import Rest from '../Rest'

export default class UserService extends Rest {

  /**
   * @type {String}
   */
  static resource = '/api/v1/users'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  internalStore(data) {
    return this.post('/internal_store', data).then(data => {
      return data
    })
  }

  permissions() {
    return this.get('/permissions').then(data => {
      return data
    })
  }

  getUsers() {
    return this.get('/get/users').then(data => {
      return data
    })
  }

  changeRole(type: String) {
    return this.post(`/change_role`, {type: type}).then(data => {
      return data
    })
  }

  sendTextMessageNotification(userId, message = '') {
    return this.post(`${userId}/send_text_notification`, {message: message}).then(data => {
      return data
    })
  }

  findUser(params) {
    return this.get('/find/',params).then((data) => {
      return data
    })
  }

  findUserByEmailInstitution(email, institution_id) {
    return this.get(``, {email: email, institution_id: institution_id}).then((data) => {
      return data
    })
  }

  activateUser(userId, data) {
    return this.put(`${userId}/activate`, data).then(data => {
      return data
    })
  }

  forgotPassword(userId, data) {
    return this.post(`${userId}/forgot_password`, data).then(data => {
      return data
    })
  }

  changePassword(userId, data) {
    return this.put(`${userId}/change_password`, data).then(data => {
      return data
    })
  }

  loginByiToken(itoken) {
    return this.get(`/integration/ilogin`, {itoken: itoken}).then((data) => {
      return data
    })
  }
}
