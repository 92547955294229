/*=========================================================================================
  File Name: moduleNaturalEntity.js
  Description: Auth Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from './moduleInstitutionState'
import mutations from './moduleInstitutionMutations'
import actions from './moduleInstitutionActions'
import getters from './moduleInstitutionGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
