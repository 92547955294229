/*=========================================================================================
  File Name: moduleNaturalEntityState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export function newState() {
  return {
    institutions: [],
    active_institution: {
      id: null,
      organization_id: null,
      name: null,
      created_at: null,
    },
    showModal: false,
    organizationLogo: '',
  }
}

const localStorageData: any = localStorage.getItem('institutionStore')
const state = JSON.parse(localStorageData) || newState()

export default state
