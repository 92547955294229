/*=========================================================================================
  File Name: moduleNaturalEntity.js
  Description: Auth Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from './moduleBaseQuestionState'
import mutations from './moduleBaseQuestionMutations'
import actions from './moduleBaseQuestionActions'
import getters from './moduleBaseQuestionGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
