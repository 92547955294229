<template>
  <div>
    <slot name="filter"></slot>

    <vs-table
      :sst="true"
      @change-page="handleChangePage"
      @sort="handleSort"
      @selected="$emit('selected', $event)"
      v-model="itemSelected"
      pagination
      :max-items="maxItems"
      :data="model.data"
      :total="totalItems"
      :current-page="model.current_page"
      :no-data-text="noDataText"
      current-sort-type="asc"
      :class="tableStyle"
      :max="paginationMaxItems">
      <template slot="header">
        <div class="flex flex-wrap w-full">
          <div class="vs-row w-full pb-2 gap-2 flex items-end">
            <div class="flex-grow">
              <slot name="filterZone">
              </slot>
            </div>

            <div v-if="!hide_searchbar" class="flex w-full sm:w-1/2 gap-1 items-center">
              <vs-input icon="search" v-model="searchInput" class="searchbar w-full flex-grow" @input="handleSearch(searchInput)">
              </vs-input>
              <button-alternates
                v-if="showExport"
                :label="$t('exportar')"
                buttonClass="px-4"
                :alternatives="[
                  {
                    icon: 'picture_as_pdf',
                    label: 'PDF',
                    color: 'red',
                    click: () => {exportGrid('pdf')}
                  },
                  {
                    icon: 'border_all',
                    label: 'xls',
                    color: 'success',
                    click: () => {exportGrid('xls')}
                  }
                ]"
                @click-main="exportGrid('pdf')"
              />
              <div class="flex-shrink-0 text-right" v-if="showTotalText">
                <label class="font-bold text-sm pr-1">{{showTotalText}}:</label>
                <span>{{ totalItems }}</span>
              </div>
            </div>
          </div>
          <div class="w-full">
            <slot name="gridSubHeader">
            </slot>
          </div>
        </div>
      </template>

      <template slot="thead" v-if="theadShow">
        <slot name="gridThead" v-bind:data="columns">
          <vs-th :sort-key="null" v-if="showMultipleSelect">
            <vs-checkbox v-model="headerSelected"/>
          </vs-th>
          <vs-th
            v-for="(column, key) in columns"
            :key="key"
            :sort-key="getColumnSortKey(key)"
            sort-status="asc"
            >{{ $t(column) }}</vs-th>
        </slot>
        <slot name="gridActions">
          <vs-th v-if="!hide_actions">
            {{$t('Actions')}}
          </vs-th>
        </slot>
      </template>

      <template slot-scope="{ data }">
        <slot
          name="gridData"
          v-bind:data="data"
          v-bind:destroy="destroy"
          v-bind:edit="edit"
          v-bind:fetchGridData="fetchGridData">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td class="flex justify-start" v-if="showMultipleSelect">
              <vs-checkbox
                :vs-value="selectObject ? tr: tr.id"
                v-model="selectedItems"/>
            </vs-td>
            <vs-td
              :data="data[indextr].id"
              v-for="(column, key) in columns"
              :key="key">
                <div :class="'flex ' +`${getColumnCss(data[indextr], key)}` ">
                  <feather-icon
                    :icon="getColumnIcon(data[indextr], key)"
                    svgClasses="h-5 w-5 mb-1 mr-3 stroke-current"
                    v-if="column_icon"/>

                  <div v-html-safe="`<div>${ getColumnData(data[indextr], key) }</div>`"/>
                </div>
            </vs-td>
            <vs-td v-if="!hide_actions">
              <span class="flex flex-row justify-center gap-1">
              <slot name="actions" :route_name="route_name" :edit="edit" :destroy="destroy" :rowindex="indextr" :row="tr" :data="data">
                  <feather-icon
                    v-permission="`${route_name}.edit`"
                    icon="EditIcon"
                    svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                    v-if="!hide_edit"
                    @click="edit(data[indextr].id)"
                  />
                  <feather-icon
                    icon="EyeIcon"
                    svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                    v-if="!hide_show"
                  />
                  <feather-icon
                    v-permission="`${route_name}.delete`"
                    icon="Trash2Icon"
                    svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
                    v-if="!hide_delete"
                    @click="destroy(data[indextr])"
                  />
              </slot>
              </span>
            </vs-td>
          </vs-tr>
        </slot>
      </template>
    </vs-table>
  </div>
</template>

<script>
import ReportService from '@/services/api/ReportService'
import ButtonAlternates from './ButtonAlternates.vue'
import FileDownload from 'js-file-download'
import { mimeType } from '@/util/Util'

export default {
  props: {
    hide_searchbar: {
      type: Boolean,
      default: false
    },
    hide_actions: {
      type: Boolean,
      default: false,
    },
    hide_edit: {
      type: Boolean,
      default: false,
    },
    hide_show: {
      type: Boolean,
      default: true,
    },
    hide_delete: {
      type: Boolean,
      default: false,
    },
    route_name: {
      type: String,
      default: null,
    },
    service: {
      type: Object,
      default: null,
    },
    route_grid_path: {
      type: String,
      default: '',
    },
    route_delete_path: {
      type: String,
      default: '',
    },
    order_column: {
      type: String,
      default: 'id',
    },
    direction: {
      type: String,
      default: 'asc',
    },
    delegate: {
      type: Object,
      default: null,
    },
    theadShow: {
      type: Boolean,
      default: true
    },
    showLoading: {
      type: Boolean,
      default: true
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    noDataText: {
      type: String,
      default: function () {
        return this.$t('nenhum-registro-encontrado')
      }
    },
    paginationMaxItems: {
      type: Number,
      default: 9
    },
    perPage: {
      type: Number,
      default: 10
    },
    column_formats: {
      type: Object,
      default: () => ({})
    },
    sortKeys: {
      type: Object,
      default: () => ({})
    },
    css_formats: {
      type: Object,
      default: () => ({})
    },
    showTotalText: {
      type: String,
      default: null
    },
    totalFormat: {
      type: Function,
      default: (total) => `${total}`
    },
    fetchOnMounted: {
      type: Boolean,
      default: true
    },
    showExport: {
      type: Boolean,
      default: false
    },
    exportFilename: {
      type: String,
      default: 'export'
    },
    multipleSelect: {
      type: Boolean,
      default: false
    },
    selectAllCallback: {
      type: Function,
      default: () => {}
    },
    defaultOrderColumn: {
      type: String,
      default: 'id'
    },
    requestParams: {
      type: Object,
      default: () => ({})
    },
    column_icon: {
      type: Object,
      default: () => ({})
    },
    selectObject:{ //To return object full. Not only id
      type: Boolean,
      default: false
    }
  },
  components: {
    ButtonAlternates
  },
  data() {
    return {
      model: {
        current_page: 0,
        data: [],
        last_page: 0,
        next_page_url: '',
        prev_page_url: '',
        to: 10,
        total: 0,
      },
      columns: {},
      filterMode: false,
      filter: {
        items: [''],
      },
      custom_filters: [],
      searchInput: '',
      dataToDestroy: null,
      itemSelected: null,
      exportService: null,
      headerSelected: false,
      selectedItems: []
    }
  },
  computed: {
    showMultipleSelect() {
      return this.multipleSelect
    },
    totalItems() {
      return this.model.total
    },
    maxItems() {
      return this.perPage
      // return this.hidePagination ? this.model.data.length : this.per_page
    },
    hasFilterZone() {
      return !!this.$slots['filterZone'] || !!this.$scopedSlots['filterZone']
    },
    searchbarDivWidth () {
      return this.hasFilterZone ? 'w-full md:w-1/2' : 'w-full'
    },
    hasFilters() {
      const hasAnyFilter = (this.filter.items.length !== 0 || this.custom_filters.length !== 0)

      return hasAnyFilter
    },
    tableStyle() {
      return this.hidePagination ? 'hidePagination' : ''
    }
  },
  watch: {
    itemSelected() {
      if (!this.isEmpty(this.delegate) && this.delegate.itemSelected) {
        const itemSelectedFunction = this.delegate.itemSelected()
        itemSelectedFunction(this.itemSelected)
      }
    },
    headerSelected(val) {
      if (val === true) {
        //Retirada o callBack. Unico ponto era o physicalQuestionnaireParticipants e permission in roles.
        //Analisar a real necessidade
        //this.selectAllCallback().then((selectedItems) => {
        //  this.selectedItems = selectedItems
        //})
        const gridListPerPage = this.model.data
        var self = this;
        if(gridListPerPage){
          gridListPerPage.forEach(function (item, index) {
            //console.log(item, index);
            if(self.selectObject){
              self.selectedItems.push(item)
            }else{
              self.selectedItems.push(item.id)
            }

          });
        }
      } else {
        this.selectedItems = []
      }
    },
    selectedItems(items) {
      this.$emit('changedSelection', items)
    }
  },
  methods: {
    getParams() {
      return {
        page: this.model.current_page,
        order_column: this.order_column,
        direction: this.direction,
        per_page: this.perPage,
        filters: this.filter,
        custom_filters: this.custom_filters,
        ...this.requestParams
      }
    },
    fetchGridData(reset_page = false, params = {}) {
      return new Promise((resolve, reject) => {
        if (this.service) {
          if (this.showLoading) {
            this.$vs.loading()
          }

          if (this.filter && !this.isEmpty(this.searchInput)) {
            this.filter.items[0] = this.searchInput
          } else {
            this.filter.items[0] = ''
          }

          //Resete page
          this.model.current_page = reset_page ? 1 : this.model.current_page

          this.service
            .grid(this.route_grid_path, this.getParams())
            .then(
              (response) => {
                this.$vs.loading.close()
                this.$set(this.$data, 'model', response.model)
                this.$set(this.$data, 'columns', response.columns)
                this.$emit('grid-UPDATED', response)
                resolve()
              },
              (error) => {
                this.$vs.loading.close()
                reject()
              }
            )
        } else {
          reject()
        }
      })
    },
    exportGrid(type) {
      if (this.exportService) {
        this.$vs.loading()
        this.exportService.exportGrid(this.route_grid_path, type, this.getParams()).then(
          (data) => {
            this.$vs.loading.close()
            FileDownload(data, `${this.exportFilename}.${type}`, mimeType(type))
          },
          (error) => {
            this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-essa-operacao'))
            this.$vs.loading.close()
          }
        )
      }
    },
    getColumnSortKey(key) {
      if (key in this.sortKeys) {
        return this.sortKeys[key]
      } else {
        return key
      }
    },
    getColumnCss(rowData, column) {
      const columnData = this._.get(rowData, column)
      if (column in this.css_formats && (this.css_formats[column] instanceof Function)) {
        return this.css_formats[column](columnData)
      }

      return ''
    },
    getColumnData(rowData, column) {
      const columnData = this._.get(rowData, column)
      if (column in this.column_formats && (this.column_formats[column] instanceof Function)) {
        return this.column_formats[column](columnData, rowData)
      }
      return columnData ? columnData : ''
    },
    getColumnIcon(rowData, column) {
      const columnData = this._.get(rowData, column)
      if (column in this.column_icon && (this.column_icon[column] instanceof Function)) {
        return this.column_icon[column](columnData)
      }

      return ''
    },
    edit(id) {
      this.$router.push(`${this.route_name}/${id}/edit`)
    },
    destroy(data) {
      this.dataToDestroy = data
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro'),
        accept: this.acceptDestroy,
      })
    },
    acceptDestroy() {
      if (this.delegate && this.delegate.destroy) {
        const destroyFunction = this.delegate.destroy()
        destroyFunction(this.dataToDestroy)
      } else {
        this.$vs.loading()
        this.service.destroy(this.dataToDestroy.id).then(
          (response) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Successo!',
              text: this.$t('operacao-realizada-com-sucesso'),
              color: 'success',
              iconPack: 'feather',
              position: 'top-center',
              icon: 'icon-check-circle',
            })

            this.fetchGridData()
          },
          (error) => {
            this.$vs.loading.close()
          }
        )
      }
    },
    handleSearch(searching) {
      this.searchInput = searching
      if (searching === '') {
        this.filter.items = []
      }
      // Debounce fetch
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.fetchGridData(true)
      }, 1000)
    },
    handleFilter(value, filter) {
      this.timeout = setTimeout(() => {
        this.custom_filters[filter] = value
        this.fetchGridData(true)
      }, 1000)
    },
    handleChangePage(page) {
      this.model.current_page = page
      return this.fetchGridData()
    },
    handleSort(key, direction) {
      this.order_column = !this.isEmpty(key)
        ? key.toLowerCase() : this.order_column ? this.order_column : this.defaultOrderColumn

      if (direction !== null) {
        this.direction = direction
      } else {
        this.direction = 'asc'
      }

      this.$emit('update:direction', this.direction)

      if (direction === null) {
        this.order_column = this.defaultOrderColumn
      }

      this.$emit('update:order_column', this.order_column)

      if (key === null) return
      else this.fetchGridData(true)
    },
    // Updates custom filters from param.
    fillCustomFilters(customFilters) {
      customFilters.forEach(filter => {
        this.custom_filters[filter.key] = filter.value
      })
    },
    // Replaces custom filters and items with params and fetch data.
    flashFilters(items, custom) {
      this.custom_filters = custom
      this.filter.items = items
      return this.handleChangePage(1)
    },
    resetFilters() {
      this.filter.items = []
      this.custom_filters = []
      this.searchInput = []
    },
    applySimpleContent(content) {
      this.model.data = content
    },
    dlog(...things) {
      this.devLog(`grid:${this.route_name || this.route_grid_path}`, ...things)
    }
  },
  mounted() {
    if (this.fetchOnMounted) {
      this.fetchGridData()
    }
    if (this.showExport) {
      this.exportService = ReportService.build(this.$vs)
    }
    this.$emit('ready', this)
  },
}
</script>

<style>

  .searchbar {
    width: 100% !important;
  }

  .searchbar input {
    height: 38px;
  }
  .searchbar .vs-input--icon {
    top: 11px;
    left: 8px;
    border: none;
  }
  .searchbar > .vs-con-input > input {
    padding-left: 2rem !important;
  }

  /* .hidePagination {
    background-color: #ff99ff;
  } */

  .hidePagination div.con-vs-pagination {
    display: none;
  }
</style>
