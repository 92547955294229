/*=========================================================================================
  File Name: moduleNaturalEntityGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  federationUnitList: state => {
    return state.federationUnitList
  },
  currentTour: state => {
    return state.currentTour
  },
  isDisabledContextMenu: state => {
    return state.disabledContextMenu
  },
  showRoleModal: state => {
    return state.changeRole
  },
  smallLogo: state => {
    return state.small_logo
  },
  logo: state => {
    return state.logo
  },
  domainConfig: state => {
    return state.domain_config
  }
}
