/*=========================================================================================
  File Name: moduleNaturalEntity.js
  Description: Auth Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from './moduleCommonState'
import mutations from './moduleCommonMutations'
import actions from './moduleCommonActions'
import getters from './moduleCommonGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
