/*=========================================================================================
  File Name: moduleNaturalEntityGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  selectedIDs: state => {
    const tags: Array<Object> = []
    state.selectedQuestions.forEach(question => {
      tags.push({
        'text': question.id,
        tiClasses: ['ti-valid'],
        classes: 'bg-primary',
        data: question
      })
    })
    return tags
    
  },
  selectedQuestions: state => state.selectedQuestions
}
