import axios from 'axios'
import router from '@/router'
import store from '@/store/store'
import { getTextReadableTime, responseMessagesHasErrorCode, getSubdomain  } from '@/util/Util'
import { NotificationActionCodes } from '@/util/Exceptions'
import i18n from '@/i18n/i18n'

let vs: any
let baseUrl = process.env.VUE_APP_API_BASE_URL

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

const axiosStandard = axios.create({
  baseURL: baseUrl,
  timeout: 100000,
  transformResponse: [
    function(data) {
      return data
    }
  ]
})

axiosStandard.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${getToken()}`
    const activeInstitution = store.getters['institution/active']
    if (activeInstitution.id) {
      config.headers['Institution-Id'] = activeInstitution.id
    }

    const locale = i18n.locale
    if (locale) {
      config.headers['locale'] = locale
    }

    const host = window.location.host
    if (host) {
      config.headers['front-host'] = host
    }

    if (store && store.getters.appVersion) {
      config.headers['app-version'] = store.getters.appVersion
    }

    // if (process.env.NODE_ENV === 'development') {
    //   if (config.params) {
    //     config.params.append('XDEBUG_SESSION_START', 'PHPSTORM')
    //   } else {
    //     config.params = {
    //       XDEBUG_SESSION_START: 'PHPSTORM'
    //     }
    //   }
    // }

    return config
  },
  error => {
    Promise.reject(error)
  }
)

axiosStandard.interceptors.response.use(
  response => {
    store.dispatch('setLastRequest', Date.now())
    return response
  },
  function(error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('userInfo')
      router.push('/login')
    } else if (error.response && error.response.status === 403) {
      const errorData = JSON.parse(error.response.data)
      showMessage(errorData)
      router.push('/')
    } else if (error.response && error.response.status === 503) {
      //const msgException = JSON.parse(error.response.data)
      //if(msgException && msgException.exception
      //                && msgException.exception.indexOf('MaintenanceModeException') !== -1){
        router.push('/maintenance') // maintenancePage
      //}
    } else if (vs) {
      const errorData = JSON.parse(error.response && error.response.data)
      showMessage(errorData)
    }

    try {
      const errorResponse = (error.response.status === 422) && JSON.parse(error.response.data)
      if(errorResponse && ('code' in errorResponse)) {
        if (errorResponse.code === NotificationActionCodes.FIRSTACCESS) {
          router.push('/first-access')
        } else if (errorResponse.code === NotificationActionCodes.ANSWERFINISHED) {
          router.push('/questionnaires/answers')
        } else if (errorResponse.code === NotificationActionCodes.DISABLEANSWER) {
          store.dispatch('formAnswer/disableFormAnswer')
        }
      }
    } catch (error) {
      return Promise.reject(error.response)
    }

    return Promise.reject(error.response)
  }
)


function showMessage(errorData: any) {
  if (errorData.messages != undefined) {
    const messages = JSON.parse(errorData.messages)

    messages.forEach((message: any[]) => {
      if (typeof message === 'string' || message instanceof String) {
        const timeLength = getTextReadableTime(message as any)
        vs.notify({
          time: timeLength,
          title: 'Alerta',
          text: message,
          iconPack: 'feather',
          icon: 'icon-x-circle',
          color: 'danger',
          position: 'top-center'
        })
      } else {
        message.forEach(element => {
          let icon = 'icon-alert-triangle'
          let title = ''
          let color = 'success'
          let time = 3000
          const fixed = false
          let position = 'bottom-right'
          if (element instanceof String) {
            icon = 'icon-x-circle'
            title = 'Error'
            color = 'danger'
            position = 'top-center'
            time = 5000
          } else {
            switch (element.level) {
            case 'success': {
              icon = 'icon-check-circle'
              title = 'Success'
              color = 'success'
              position = 'bottom-right'
              break
            }
            case 'error': {
              icon = 'icon-x-circle'
              title = 'Error'
              color = 'danger'
              position = 'top-center'
              time = 5000
              break
            }
            case 'warning': {
              icon = 'icon-alert-circle'
              title = 'Warning'
              color = 'warning'
              position = 'bottom-right'
              break
            }
            }
          }
          const timeLength = getTextReadableTime(element.message)

          vs.notify({
            timeLength,
            title,
            text: element.message,
            iconPack: 'feather',
            icon,
            color,
            position
          })
        })
      }
    })
  }
}

function getToken() {
  let token = ''
  token = store.getters.userToken || ''
  return token
}

const standard = {
  axios() {
    return axiosStandard
  },
  token() {
    return getToken()
  },
  setVs(_vs: any) {
    vs = _vs
  },
  setBaseUrl(_baseUrl: any) {
    baseUrl = _baseUrl
  }
}

export default standard
