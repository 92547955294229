/*=========================================================================================
  File Name: moduleNaturalEntity.js
  Description: Auth Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from './moduleFormAnswerState'
import mutations from './moduleFormAnswerMutations'
import actions from './moduleFormAnswerActions'
import getters from './moduleFormAnswerGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
