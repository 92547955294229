import axiosStandard from './standard'

export default class Http {

  private path: string
  private http: any

  /**
   * @param {String} path
   * @param {Object} http
   */
  constructor(path: string, http = null, vs = null, baseURL = null) {
    this.path = path
    axiosStandard.setVs(vs)

    if (http) {
      this.http = http
    } else {
      this.http = axiosStandard.axios()
    }

  }

  /**
   * @param {String} path
   * @param {Object} options
   */
  static build(path: any, baseURL: any) {
    return new this(path, null, null, baseURL)
  }

  /**
   * @param {String} url
   * @returns {*|Promise<any>}
   */
  get(url: string, data: any = null, extraOptions={}): Promise<any> {
    const params = new URLSearchParams()
    for (const key in data) {
      if (data[key] instanceof Object) {
        for (const objKey in data[key]) {
          const obj = data[key][objKey]
          if (obj instanceof Array) {
            obj.forEach(element => {
              params.append(`${key}[${objKey}][]`, element)
            })
          } else {
            params.append(`${key}[${objKey}]`, obj)
          }
        }
      } else {
        params.append(key, data[key])
      }
    }

    return this.http
      .get(Http.normalize(this.path, url), { params: params, ...extraOptions })
      .then(Http.then)
  }

  /**
   * @param {String} url
   * @returns {*|Promise<any>}
   */
  getAllById(id: Number, data: any = null, extraOptions={}): Promise<any> {
    const params = new URLSearchParams()
    for (const key in data) {
      if (data[key] instanceof Object) {
        for (const objKey in data[key]) {
          const obj = data[key][objKey]
          if (obj instanceof Array) {
            obj.forEach(element => {
              params.append(`${key}[${objKey}][]`, element)
            })
          } else {
            params.append(`${key}[${objKey}]`, obj)
          }
        }
      } else {
        params.append(key, data[key])
      }
    }

    return this.http
      .get(Http.normalize(this.path, `/${id}`), { params: params, ...extraOptions })
      .then(Http.then)
  }

  /**
   * @param {String} url
   * @returns {*|Promise<any>}
   */
  getBlob(url: string, data: any = null): Promise<any> {
    return this.get(url, data, {
      responseType: 'blob'
    })
  }

  /**
   * @param {String} url
   * @param {Object} data
   * @returns {*|Promise<any>}
   */
  post(url: string, data: any = {}, headers: any = {}): Promise<any> {

    return this.http
      .post(Http.normalize(this.path, url), data, headers)
      .then(Http.then)
  }

  /**
   * @param {String} url
   * @param {Object} data
   * @returns {*|Promise<any>}
   */
  put(url: string, data: any) {
    return this.http.put(Http.normalize(this.path, url), data).then(Http.then)
  }

  /**
   * @param {String} url
   * @param {Object} data
   * @returns {*|Promise<any>}
   */
  patch(url: string, data: any) {
    return this.http.patch(Http.normalize(this.path, url), data).then(Http.then)
  }

  /**
   * @param {String} url
   * @returns {*|Promise<any>}
   */
  delete(url: string) {
    return this.http.delete(Http.normalize(this.path, url)).then(Http.then)
  }

  download(url: string, fileName: string, mimeType: string) {
    return this.http
      .get(Http.normalize(this.path, url), { responseType: 'blob' })
      .then(response => {
        // const url = window.URL.createObjectURL(new Blob([data]))
        // const link = document.createElement('a')
        // link.href = url
        // link.setAttribute('download', fileName)
        // document.body.appendChild(link)
        // link.click()

        const blobData = [response.data]
        const blob = new Blob(blobData, {
          type: mimeType || 'application/octet-stream'
        })

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were
          // revoked by closing the blob for which they were created.
          // These URLs will no longer resolve as the data backing
          // the URL has been freed."
          window.navigator.msSaveBlob(blob, fileName)
        } else {
          const blobURL = (window.URL ? window.URL: window.webkitURL).createObjectURL(blob)
          const tempLink = document.createElement('a')
          tempLink.style.display = 'none'
          tempLink.href = blobURL
          tempLink.setAttribute('download', fileName)

          // Safari thinks _blank anchor are pop ups. We only want to set _blank
          // target if the browser does not support the HTML5 download attribute.
          // This allows you to download files in desktop safari if pop up blocking
          // is enabled.
          if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
          }

          document.body.appendChild(tempLink)
          tempLink.click()

          // Fixes "webkit blob resource error 1"
          setTimeout(function() {
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(blobURL)
          }, 0)
        }
      })
  }

  /**
   * @param {Object} response
   * @returns {Object}
   */
  static then(response: any) {
    if (!response.data) {
      return {}
    }
    if (typeof response.data === 'string') {
      return JSON.parse(response.data)
    }
    return response.data
  }

  /**
   * @param {String} start
   * @param {String} end
   * @returns {String}
   */
  static normalize(start: string, end: string) {
    return `${start}/${end}`.replace(/([^:]\/)\/+/g, '$1')
  }
}
