/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import moduleAuth from './auth/moduleAuth'
import moduleBaseQuestion from './basequestion/moduleBaseQuestion'
import common from './common/moduleCommon'
import moduleInstitution from './institution/moduleInstitution'
import moduleFormAnswer from './formanswer/moduleFormAnswer'

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    basequestion: moduleBaseQuestion,
    institution: moduleInstitution,
    formAnswer: moduleFormAnswer,
    auth: moduleAuth,
    common
  },
  strict: process.env.NODE_ENV !== 'production'
})
