import pt from './locales/pt.json'
import en from './locales/en.json'
import fr from './locales/fr.json'
import es from './locales/es.json'

export default {
  pt: pt,
  en: en,
  fr: fr,
  es: es,
}
