<template>
  <div class="flex items-center justify-center">
    <vs-button :class="`alternates-button ${buttonClass}`" @click="$emit('click-main', $event)"
      :icon="iconMain">
      {{label}}
    </vs-button>
    <vs-dropdown v-if="alternatives.length > 0">
      <vs-button :class="`alternates-dropdown ${dropdownClass}`" type="filled" icon="expand_more"></vs-button>
      <vs-dropdown-menu>
        <vs-dropdown-item :key="alternate" v-for="(alternate) in alternatives">
          <div :class="`flex items-center justify-start cursor-pointer ${'color' in alternate ? `text-${alternate.color}` : ''}`" @click="alternateClicked(alternate)">
            <vs-icon v-if="'icon' in alternate" :icon="alternate.icon"/>
            <div class="vs-dropdown-item-link-text px-4">{{alternate.label}}</div>
          </div>
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>

export default {

  props: {
    label: {
      type: String,
      default: function () {
        return this.$t('botao')
      }
    },
    iconMain:{
      type: String,
      default: null
    },
    alternatives: {
      type: Array,
      default: () => []
    },
    buttonClass: {
      type: String,
      defaul: ''
    },
    dropdownClass: {
      type: String,
      default: ''
    }
  },

  methods: {
    alternateClicked(alternate) {
      this.$emit('click-alternate', alternate)
      alternate.click()
    }
  }
}
</script>

<style lang="scss">
.alternates-button {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.alternates-dropdown {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 39px !important;
}
.vs-dropdown-item-link-text{
  width: max-content !important;
}
</style>
