/*=========================================================================================
  File Name: moduleNaturalEntityMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  UPDATE_FEDERATION_UNIT_LIST(state: any, federationUnitList: Object) {
    state.federationUnitList = federationUnitList
  },
  UPDATE_CURRENT_TOUR(state: any, currentTour: String) {
    state.currentTour = currentTour
  },
  ROLE_MODAL_VISIBILITY(state:any, show: Boolean) {
    state.changeRole = show
  },
  DISABLED_CONTEXT_MENU(state:any, value: Boolean) {
    state.disabledContextMenu = value
  },
  UPDATE_SMALL_LOGO(state:any, logo_small: any) {
    state.small_logo = logo_small
  },
  UPDATE_LOGO(state:any, logo: any) {
    state.logo = logo
  },
  UPDATE_DOMAIN_CONFIG(state:any, domain_config: any) {
    state.domain_config = domain_config
  },
}
