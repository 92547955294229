/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {
  // COMPONENT
  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {
    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return 'xl'
    else if (state.windowWidth >= 992) return 'lg'
    else if (state.windowWidth >= 768) return 'md'
    else if (state.windowWidth >= 576) return 'sm'
    else return 'xs'
  },

  windowWidth: state => {
    return state.windowWidth
  },

  scrollbarTag: state => {
    return state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
  },
  activePerson: state => {
    return state.activePerson
  },
  appVersion: state => {
    return state.packageVersion
  },
  AppActiveUser: state => {
    return state.AppActiveUser
  },
  activeProfile: state => {
    return state.activeRole.name
  },
  userRoles: state => {
    return state.AppActiveUser.roles
  },
  firstAccess: state => {
    return state.AppActiveUser.firstAccess
  },
  userName: state => {
    return state.AppActiveUser.name
  },
  userEmail: state => {
    return state.AppActiveUser.email
  },
  userId: state => {
    return state.AppActiveUser.id
  },
  userToken: state => {
    return state.AppActiveUser.token
  },
  unreadNotifications: state => {
    return state.unreadNotifications
  },
  serverOnline: state => {
    return state.serverOnline
  },
  internetConnection: state => {
    return state.internetConnection
  },
  lastRequestMadeAt: state => {
    return state.lastRequest
  },
  updatesWontPersist: state => {
    return !state.internetConnection || !state.serverOnline
  },
  pingInterval: state => {
    return state.pingInterval
  },
  slowKbpsRate: state => {
    return state.slowKbpsRate
  },
  frozenRouter: state => {
    return state.frozenRouter
  },
  themePrimaryColor: state => {
    return state.themePrimaryColor
  },
  defaultThemePrimaryColor: state => {
    return state.defaultThemePrimaryColor
  }
}

export default getters
