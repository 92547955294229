/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const actions = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val)
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo({ commit }, payload) {
    if (payload.roles) {
      //This is the one call that makes the active role be updated all over the place!
      commit('UPDATE_DEFAULT_ACTIVE_ROLE', payload.roles)
    }
    commit('UPDATE_USER_INFO', payload)
  },
  updateActivePerson({ commit }, payload) {
    commit('UPDATE_ACTIVE_PERSON', payload)
  },
  resetUserInfo({ commit }) {
    commit('RESET_USER_INFO')
    commit('RESET_ACTIVE_PERSON')
    commit('RESET_ACTIVE_ROLE')
    commit('SET_UNREAD_NOTIFICATIONS', [])
  },
  addUnreadNotification({ commit }, payload) {
    commit('ADD_UNREAD_NOTIFICATION', payload)
  },
  resetUnreadNotifications({commit}, payload = []) {
    commit('SET_UNREAD_NOTIFICATIONS', payload)
  },
  markAsReadNotification({commit}, notifications) {
    if (Array.isArray(notifications)) {
      notifications.forEach((notification) => {
        commit('MARK_READ_NOTIFICATION', notification)
      })
    } else {
      commit('MARK_READ_NOTIFICATION', notifications)
    }
  },
  setConnectivity({commit}, status) {
    if (status && 'server' in status) {
      commit('SET_SERVER_STATUS', status.server)
    }
    if (status && 'web' in status) {
      commit('SET_WEB_STATUS', status.web)
    }
  },
  setLastRequest({commit}, date) {
    commit('SET_LAST_REQUEST', date)
  },
  setPingInterval({commit}, milliseconds) {
    commit('SET_PING_INTERVAL', milliseconds)
  },
  setSlowKbpsRate({commit}, kbps) {
    commit('SET_SLOW_KBPS_RATE', kbps)
  },
  freezeRouter({commit}) {
    commit('FREEZE_ROUTER')
  },
  thawRouter({commit}) {
    commit('THAW_ROUTER')
  }
}

export default actions
