import Vue from 'vue'
import router from '@/router'
import store from '@/store/store'
import { isString } from 'lodash'

export default {
  updateAvailableInstitutions({commit}:any, payload: Array<any>) {
    commit('SET_INSTITUTIONS', payload)
  },
  updateOrganizationLogo({commit}: any, payload: string) {
    if (isString(payload)) {
      commit('SET_ORGANIZATION_LOGO', payload)
    }
  },
  selectActiveInstitution({commit}:any, id: number) {
    if (id === null) {
      commit('CLEAR_ACTIVE_INSTITUTION', id)
    } else {
      commit('SELECT_ACTIVE_INSTITUTION', id)
    }
  },
  updateModalVisibility({commit}:any, payload: Boolean) {
    commit('SET_MODAL_VISIBILITY', payload)
  },
  resetStore({commit}:any) {
    commit('RESET_STORE')
  }
}
