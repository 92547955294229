/*=========================================================================================
  File Name: moduleNaturalEntityMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import remove from 'lodash/remove'

export default {
  ADD_SELECTED_QUESTION(state: any, question: Object) {
    state.selectedQuestions.push(question)
  },
  REMOVE_SELECTED_QUESTION(state: any, questionId) {
    const index = state.selectedQuestions.findIndex((question) => question.id === questionId)
    state.selectedQuestions.splice(index, 1)
  },
  WIPE_SELECTIONS(state: any) {
    state.selectedQuestions = []
  }
}
