<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses"/>
  </div>
</template>

<script>
import themeConfig from '@/../themeConfig'
import Echo from 'laravel-echo'
import { WebOfflineEvent, WebOnlineEvent } from './models/events/DOMEvents'
import VueUtils from './util/VueUtils'
import DomainConfigService from '@/services/api/DomainConfigService.ts'
import VeeValidate from 'vee-validate'

export default {
  data() {
    return {
      vueAppClasses: []
    }
  },
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl'(val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    },
    // Important Echo Instance Refresher
    '$store.getters.userToken'(val) {
      if (this._.has(window, 'Echo')) {
        /**
         * 4-step refresh process (E - happens if token is empty)
         * 1 - Leave all channels       (E)
         * 2 - Disconnect               (E)
         * 3 - Change token in options  (*)
         * 4 - Reconnect                (!E)
         */

        // 1. Echo leave channels if empty token.
        if (this.isEmpty(val)) {
          const channels = Object.keys(window.Echo.connector.channels)
          channels.forEach((channel) => {
            window.Echo.leaveChannel(channel)
          })
        }

        // 2. Echo disconnect if empty token.
        if (this.isEmpty(val)) {
          window.Echo.disconnect()
        }

        // 3. Echo change option token.
        if (this._.has(window, 'Echo.options.auth.headers')) {
          console.log('Echo instance Authorization Header updated.')
          window.Echo.options.auth.headers.Authorization = `Bearer ${val}`
        }

        // 4. Echo re-connect if not empty token.
        if (!this.isEmpty(val)) {
          window.Echo.connect()
        }

      }
    }
  },
  methods: {
    toggleClassInBody(className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) {
          document.body.classList.remove('theme-semi-dark')
        }
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) {
          document.body.classList.remove('theme-dark')
        }
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark')) {
          document.body.classList.remove('theme-dark')
        }
        if (document.body.className.match('theme-semi-dark')) {
          document.body.classList.remove('theme-semi-dark')
        }
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
      this.$store.commit('UPDATE_WINDOW_HEIGHT', window.outerHeight)

      // Set --vh property
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      )
    },
    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    },
    checkDomainConfig() {

      const activeInstitution = this.$store.getters['institution/active']
      const organizationLogo = activeInstitution.organization ? activeInstitution.organization.logo ? activeInstitution.organization.logo  : null : null

      if (organizationLogo) {
        this.$store.dispatch('common/updateSmallLogo', organizationLogo)
      }

      const domainConfigService = DomainConfigService.build(this.$vs)
      domainConfigService.get('').then(data => {

        this.$store.dispatch('common/updateDomainConfig', data)

        if (data.logos && !organizationLogo) {

          if (data.logos.smallLogo) {
            this.$store.dispatch('common/updateSmallLogo', data.logos.smallLogo)
          }
          if (data.logos.logo) {
            this.$store.dispatch('common/updateLogo', data.logos.logo)
          }

          this.$vs.loading.close('#logo-container > .con-vs-loading')
        }

        if (data.primaryColor) {
          this.$store.commit('UPDATE_PRIMARY_COLOR', data.primaryColor)
          this.$store.commit('UPDATE_DEFAULT_PRIMARY_COLOR', data.primaryColor)
          this.$vs.theme({ primary: data.primaryColor })
        }
      })

    }
  },
  mounted() {

    const momentMessages = this._.get(this.$i18n, `messages.${this.$i18n.locale}.moment`, null)
    if (momentMessages) {
      this.$moment.defineLocale('pt-br', this.$i18n.messages[this.$i18n.locale].moment)
    }
    this.toggleClassInBody(themeConfig.theme)

    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    // We need the absolute outer height, one that disregards the devtools.
    this.$store.commit('UPDATE_WINDOW_HEIGHT', window.outerHeight)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    document.addEventListener('contextmenu', event => {
      this.$root.$emit('rightClick', event)
      if (this.$store.getters['common/isDisabledContextMenu']) {
        if (process.env.NODE_ENV !== 'development') {
          event.preventDefault()
        }
      }
    })

    /*
    window.addEventListener('error', (event) => {
      try {
        const type = this._.get(event, 'type')
        const name = this._.get(event, 'name')
        const message = this._.get(event, 'message')

        this.$exceptionService.quickRegister(
          'Erro javascript',
          'javascript_general',
          {
            type: type,
            name: name,
            message: message,
          }
        )
      } catch (e) {
        // Do nothing, prevent the error from making another error
      }
    })
    */

    this.checkDomainConfig()

    this.$i18n.locale = this.$utils.browser.getDefaultLocale()
    // VeeValidate.Validator.setLocale(this.$i18n.locale)
  },
  async created() {
    // jwt
    // jwt.init()

    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('online', (event) => {
      this.$store.dispatch('setConnectivity', {web: true})
      this.$root.$emit('webOnline', new WebOnlineEvent(event))
    })
    window.addEventListener('offline', (event) => {
      this.$store.dispatch('setConnectivity', {web: false})
      this.$root.$emit('webOffline', new WebOfflineEvent(event))
    })

    // Auth0
    try {
      await this.$auth.renewTokens()
    } catch (e) {
      console.error(e)
    }
  },
  beforeCreate() {
    if (this.socketEnabled()) {
      window.io = require('socket.io-client')
      window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: `${process.env.VUE_APP_API_SOCKET_URL}:6001`,
        auth: {
          headers: {
            Authorization: `Bearer ${this.$store.getters.userToken}`
          }
        }
      })
    }

    /*
    this.$loadScript(
      'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image'
    )
      .then(() => {
        try {
          window.wiris = com.wiris.js.JsPluginViewer
        } catch (e) { }
      })
      .catch(() => {})
    */

  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
