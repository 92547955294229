import { isConditionPassed } from './util'
// import store from '@/store/store'

export default {
  install(Vue, option: any = {}) {
    const userInfoStr = localStorage.getItem('userInfo')
    const userInfo: any = userInfoStr ? JSON.parse(userInfoStr) : []

    const container = {
      permissions: userInfo.permissions || []
    }

    Vue.prototype.$acl = {
      /*
      |-------------------------------------------------------------------------
      | Setters
      |-------------------------------------------------------------------------
      |
      | These functions controls the "permissions" from a custom array.
      |
      */

      setPermissions: permissions => {
        container.permissions = permissions
      },

      /*
      |-------------------------------------------------------------------------
      | Getters
      |-------------------------------------------------------------------------
      |
      | These functions return the "permissions" and "roles" stored.
      | This is useful when you want list all data.
      |
      */

      getPermissions: () => container.permissions,

      /*
      |-------------------------------------------------------------------------
      | Directives
      |-------------------------------------------------------------------------
      |
      | These is a group of functions for Vue Directives.
      | This is useful when you want valid a "permission" or "role"
      | programmatically.
      |
      */

      // Permissions
      hasPermission: permission => {
        if (process.env.VUE_APP_CHECK_PERMISSION === 'true') {   
          return container.permissions.some(p =>
            permission === p.name
          )
        }
        return true
      },
      unlessPermission: permission => {
        return !Vue.prototype.$acl.hasPermission(permission)
      },
      hasAnyPermission: values => {
        if (process.env.VUE_APP_CHECK_PERMISSION === 'true') {
          if (values) {
            const permissions = values.split('|')
            if (permissions === undefined) return false
            return permissions.some(permission =>
              container.permissions.some(p =>
                permission === p.name
              )
            )
          }
        }
        return true
      },

      hasAllPermissions: values => {
        if (process.env.VUE_APP_CHECK_PERMISSION === 'true') {
          const permissions = values.split('|')
          return permissions.every(permission =>
            container.permissions.includes(permission.name)
          )
        }
        return true
      }
    }

    // Directives
    Vue.directive('permission', {
      bind() {
        // const permissions: Array<string> = Vue.prototype.$acl.getPermissions()
        // if (permissions && permissions.length > 0) return
        // const userInfoStr = localStorage.getItem('userInfo')
        // const userInfo: any = userInfoStr ? JSON.parse(userInfoStr) : []

        // Vue.prototype.$acl.setPermissions(userInfo.permissions)
      },
      inserted: isConditionPassed(Vue)
    })

    // Alias for "v-permission:has"
    Vue.directive('can', {
      inserted: (el, binding) => {
        if (!Vue.prototype.$acl.hasPermission(binding.value)) {
          // Remove DOM Element
          el.parentNode.removeChild(el)
        }
      }
    })
  }
}
