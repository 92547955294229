/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import VxTooltip from './layouts/components/vx-tooltip/VxTooltip.vue'
import VxCard from './components/vx-card/VxCard.vue'
import VxList from './components/vx-list/VxList.vue'
import VxBreadcrumb from './layouts/components/VxBreadcrumb.vue'
import FeatherIcon from './components/FeatherIcon.vue'
import VxInputGroup from './components/vx-input-group/VxInputGroup.vue'

Vue.component(VxTooltip.name, VxTooltip)
Vue.component(VxCard.name, VxCard)
Vue.component(VxList.name, VxList)
Vue.component(VxBreadcrumb.name, VxBreadcrumb)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VxInputGroup.name, VxInputGroup)

// v-select component
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.methods.deselect = () => ({
  Deselect: {
    render: (createElement: any) =>
      createElement('feather-icon', {
        props: {
          icon: 'XIcon',
          svgClasses: 'w-4 h-4 mt-1'
        }
      })
  },
  OpenIndicator: {
    render: (createElement: any) =>
      createElement('feather-icon', {
        props: {
          icon: 'ChevronDownIcon',
          svgClasses: 'w-5 h-5'
        }
      })
  }
})

Vue.component(vSelect)

import LoadScript from 'vue-plugin-load-script'
// @ts-ignore: Improper typings on LoadScript lib.
Vue.use(LoadScript)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import VueSecureHTML from 'vue-html-secure'
Vue.use(VueSecureHTML)

// import VideoPlayer from 'vue-videojs7'

// Vue.use(VideoPlayer, /* {
//   options: global default videojs options,
//   events: global videojs videojs events
// } */)

// import videojs from 'video.js'
// Vue.prototype.$video = videojs
import '@/components/video.js/EventBarCustom.js'
// videojs.registerComponent('EventBarCustom', EventBarCustom)


// VeeValidate
import VeeValidate, { Validator } from 'vee-validate'
import pt from 'vee-validate/dist/locale/pt_BR'
import i18n from './i18n/i18n'

import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

Vue.use(VeeValidate, {
  i18n,
  dictionary: {
    pt: {
      messages: pt.messages,
      attributes: i18n.messages.pt.fields
    }
  }
})

Validator.localize('pt', pt)

VeeValidate.Validator.extend('verify_password_pt', {
  getMessage: field =>
    'A senha deve conter pelo menos: 1 letra maiúscula, 1 letra minúscula e 1 número',
  validate: value => {
    const strongRegex = new RegExp(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{6,}'
    )
    return strongRegex.test(value)
  }
})

VeeValidate.Validator.extend('verify_password_en', {
  getMessage: field =>
    'The password must contain at least: 1 uppercase letter, 1 lowercase letter and 1 number',
  validate: value => {
    const strongRegex = new RegExp(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{6,}'
    )
    return strongRegex.test(value)
  }
})
