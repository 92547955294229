/*=========================================================================================
  File Name: moduleNaturalEntityState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const state = {
  federationUnitList: null,
  currentTour: null,
  changeRole: false,
  disabledContextMenu: false,
  small_logo: null,
  logo: null,
  domain_config: null
}

export default state
