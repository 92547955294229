export const ClassHoverDirective = {
  bind(el, binding, vnode) {
    const { value = '' } = binding
    el.addEventListener('mouseenter', () => {
      el.classList.add(value)
    })
    el.addEventListener('mouseleave', () => {
      el.classList.remove(value)
    })
  },
  unbind(el, binding, vnode) {
    el.removeEventListener('mouseenter')
    el.removeEventListener('mouseleave')
  }
}

export const ESCDirective = {
  bind(el, binding, vnode) {
    el._keydownCallback = (event) => {
      if (event.key === 'Escape') {
        binding.value()
      }
    }
    document.addEventListener('keydown', el._keydownCallback)
  },
  unbind(el, binding, vnode) {
    document.removeEventListener('keydown', el._keydownCallback)
    delete el._keydownCallback
  }
}
