import { newState } from '@/store/institution/moduleInstitutionState'

function persistState(state) {
  localStorage.setItem('institutionStore', JSON.stringify(state))
}

function resetPersistence() {
  localStorage.removeItem('institutionStore')
}

export default {
  SET_INSTITUTIONS(state: {institutions: Array<any>}, institutions: Array<any>) {
    state.institutions = institutions
    persistState(state)
  },
  SET_ORGANIZATION_LOGO(state: {organizationLogo: string}, logo: string) {
    state.organizationLogo = logo
    persistState(state)
  },
  SET_MODAL_VISIBILITY(state: {showModal}, visibility: boolean) {
    state.showModal = visibility
    persistState(state)
  },
  SELECT_ACTIVE_INSTITUTION(state: {active_institution: Object, institutions: Array<any>}, id: number) {
    state.active_institution = state.institutions.find((institution) => institution.id === id)
    persistState(state)
  },
  CLEAR_ACTIVE_INSTITUTION(state: {active_institution: Object, institutions: Array<any>}, id: number) {
    state.active_institution = {
      id: null,
      organization_id: null,
      name: null,
      created_at: null
    }
    persistState(state)
  },
  RESET_STORE(state: any) {
    state = newState()
    resetPersistence()
  }
}
