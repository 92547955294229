/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import {newUserInfo} from '@/util/Constants'

const mutations = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state: any, value: any) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON(state: any, val: any) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE(state: any, val: any) {
    state.mainLayoutType = val
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state: any, val: any) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state: any, width: any) {
    state.verticalNavMenuWidth = width
  },

  // VxAutoSuggest

  UPDATE_STARRED_PAGE(state: any, payload: any) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList['pages'].data.findIndex(
      (item: { url: any }) => item.url === payload.url
    )

    // update the main list
    state.navbarSearchAndPinList['pages'].data[index].is_bookmarked =
      payload.val

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList['pages'].data[index])
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex(
        (item: { url: any }) => item.url === payload.url
      )

      // remove item using index
      state.starredPages.splice(index, 1)
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(
    state: { starredPages: any },
    list: string | any[]
  ) {
    const starredPagesMore: any = state.starredPages.slice(10)
    state.starredPages = list.concat(starredPagesMore)
  },
  ARRANGE_STARRED_PAGES_MORE(
    state: { starredPages: any },
    list: string | any[]
  ) {
    let downToUp = false
    const lastItemInStarredLimited = state.starredPages[10]
    const starredPagesLimited = state.starredPages.slice(0, 10)
    state.starredPages = starredPagesLimited.concat(list)

    state.starredPages.slice(0, 10).map((i: any) => {
      if (list.indexOf(i) > -1) downToUp = true
    })

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited)
    }
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state: { bodyOverlay: any }, val: any) {
    state.bodyOverlay = val
  },
  UPDATE_PRIMARY_COLOR(state: { themePrimaryColor: any }, val: any) {
    state.themePrimaryColor = val
  },
  UPDATE_DEFAULT_PRIMARY_COLOR(state: { defaultThemePrimaryColor: any }, val: any) {
    state.defaultThemePrimaryColor = val
  },
  UPDATE_THEME(state: { theme: any }, val: any) {
    state.theme = val
  },
  UPDATE_WINDOW_WIDTH(state: { windowWidth: any }, width: any) {
    state.windowWidth = width
  },
  UPDATE_WINDOW_HEIGHT(state: { windowHeight: any }, height: any) {
    state.windowHeight = height
  },
  UPDATE_WINDOW_SCROLL_Y(state: { scrollY: any }, val: any) {
    state.scrollY = val
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_USER_INFO(
    state: { AppActiveUser: { [x: string]: any } },
    payload: { [x: string]: any }
  ) {
    // Get Data localStorage
    const userInfo: any = localStorage.getItem('userInfo')
    const userInfoJson: any = JSON.parse(userInfo) || state.AppActiveUser

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfoJson[property] = payload[property]
      }
    }
    // Store data in localStorage
    localStorage.setItem('userInfo', JSON.stringify(userInfoJson))
  },

  UPDATE_ACTIVE_PERSON(
    state: { activePerson: { [x: string]: any } },
    payload: { [x: string]: any }
  ) {
    if (state.activePerson === null) {
      state.activePerson = {}
    }
    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.activePerson[property] = payload[property]
      }
    }
  },

  UPDATE_DEFAULT_ACTIVE_ROLE(
    state,
    roles
  ) {
    if (roles && roles.length >= 1) {
      const isTeacher = roles && roles.find( (role) => {
        return role.name === 'teacher'
      })
      state.activeRole = isTeacher || roles[0] || {name: 'none'}
    } else if(roles && length === 1) {
      state.activeRole = roles[0] || {name: 'none'}
    } else {
      state.activeRole = {name: 'none'}
    }
  },

  RESET_ACTIVE_PERSON(
    state: { activePerson: any }
  ) {
    state.activePerson = {}
  },

  RESET_ACTIVE_ROLE(
    state: { activeRole: any}
  ) {
    state.activeRole = {name: 'none'}
  },

  RESET_USER_INFO(state: { AppActiveUser: any }) {
    state.AppActiveUser = newUserInfo()
  },

  //=============================================//
  // Notifications                               //
  //=============================================//

  ADD_UNREAD_NOTIFICATION( state: { unreadNotifications: any}, notification) {
    //We dont want duplicate unread notifications in our state.
    const found = state.unreadNotifications.find(
      (unreadNotification) => unreadNotification.id === notification.id
    )
    if ( found === undefined ){
      state.unreadNotifications.unshift(notification)
    }
  },

  SET_UNREAD_NOTIFICATIONS( state: { unreadNotifications: any}, notifications) {
    state.unreadNotifications = notifications
  },

  MARK_READ_NOTIFICATION( state: { unreadNotifications:any}, notification) {
    const found = state.unreadNotifications.findIndex( (unreadNotification) => {
      return unreadNotification.id === notification.id
    })

    if ( found !== -1) {
      state.unreadNotifications.splice(found, 1)
    }
  },

  //=============================================//
  // Server Status                               //
  //=============================================//

  SET_SERVER_STATUS(state, status) {
    state.serverOnline = status
  },

  SET_WEB_STATUS(state, status) {
    state.internetConnection = status
  },

  SET_LAST_REQUEST(state, date) {
    state.lastRequest = date
  },

  SET_PING_INTERVAL(state, ms) {
    state.pingInterval = ms
  },

  SET_SLOW_KBPS_RATE(state, kbps) {
    state.slowKbpsRate = kbps
  },

  FREEZE_ROUTER(state) {
    state.frozenRouter = true
  },
  THAW_ROUTER(state) {
    state.frozenRouter = false
  }
}

export default mutations
