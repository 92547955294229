/*=========================================================================================
  File Name: moduleNaturalEntityMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  DISABLE_FORM_ANSWER(state: any) {
    state.disabled = true
  },
  ENABLE_FORM_ANSWER(state: any) {
    state.disabled = false
  }
}
