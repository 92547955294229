/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import store from '@/store/store'

export default {
   
  UPDATE_AUTHENTICATED_USER(state: any, user: any) {
    const userInfo: any = localStorage.getItem('userInfo')
    const userInfoJson: any = JSON.parse(userInfo) || store.state.AppActiveUser

    userInfoJson['token'] = user.token
    userInfoJson['firstAccess'] = user.firstAccess

    // Store data in localStorage
    localStorage.setItem('userInfo', JSON.stringify(userInfoJson))
  }
}
