import Rest from '../Rest'
import {definitions, withMissingDefaults} from '@/util/NotificationDefinitions'
//import {formattedNotification} from '@/components/notification/controller.ts'

export default class NotificationService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/users/notifications'
  _vm: any
  definitions: Object

  constructor(resource: string, http = null, vs: any, vuevm: Vue) {
    super(
      resource,
      http,
      vs,
      null
    )
    this._vm = vuevm
    this.definitions = withMissingDefaults(definitions)
  }

  //Development utility-method
  dlog(...things) {
    this._vm.devlog('notification', ...things)
  }

  grid(path: string = '', data: any) {
    if (path !== '') {
      return this.get(`${path}`, data)
    }
    return this.get('grid', data).then((data) => {
      data.model.data = this.constructManyNotifications(data.model.data)
      return data
    })
  }

  // vm is the vue instance.
  static build(vm: any) {
    return new this(this.resource, null, vm.$vs, vm)
  }

  markAsReadInDB(notifications) {
    if (Array.isArray(notifications)) {
      const uuids = notifications.map((notification) => {
        return notification.id
      })
      return this.post('/read', {uuids: uuids}).then(data => {
        return data
      })
    } else {
      return this.post(`/${notifications.id}/read`).then(data => {
        return data
      })
    }
  }

  constructManyNotifications(data) {
    return data.map((notification) => {
      return this.constructNotification(notification)
    })
  }

  markAsRead(notification) {
    return this.markAsReadInDB(notification).then(success => {
      this._vm.$store.dispatch('markAsReadNotification', notification)
    }, error => {
      this._vm.notifyError(this._vm.$vs, 'Erro não foi possível marcar a notificação como lida.')
    })
  }
  
  applyDefinition(notification) {
    if (notification && notification.type && notification.type in this.definitions) {
      return {
        ...this.definitions[notification.type],
        ...notification
      }
    }
    return {
      ...this.definitions['unknown'],
      ...notification
    }
  }

  //This method returns a javascript Closure, its essentially a pseudo-class of sorts
  applyMessageCompilation(notification) {
    const rawMessage = notification.message 
    notification.message = (truncateBy) => {
      let message = rawMessage
      message = (typeof message === 'function') ? message(this._vm, notification) : message
      if (truncateBy && truncateBy > 0) {
        return `${this._vm.truncate(this._vm.html2text(message), truncateBy)}`
      } else {
        return message
      }
    }
    return notification
  }

  constructNotification(notification) {
    // If the notification has a data property, then it came from the database,
    // in that case we fuse the database columns with all the props in data.
    if (notification.data) {
      notification = {
        sentAt: notification.created_at, //this is to guarantee that sentAt is defined if it hasnt been in data.
        ...notification,
        ...notification.data
      }
      delete notification.data
    }

    //We add the definitions from the definitions.ts file to our backend-received data.
    notification = this.applyDefinition(notification)

    //Now here we process any dynamic-generated static property, for the moment, only message is required.
    notification = this.applyMessageCompilation(notification)

    return notification
  }

  handleIncomingNotification(notification) {
    if (notification.type in definitions) {
      notification = this.constructNotification(notification)        
      const duration = notification.dropdownDuration
      const position = notification.dropdownPosition
      const title = notification.dropdownTitle
      const icon = notification.dropdownIcon

      // We fuse the notification before adding it to the store so that we have both the definition and the 
      // backend properties of the notification.
      // And also, I guarantee that the notification balloon will only show *after* it has been added to the store.
      // we dont want a fast enough user clicking on the notification before it is even in the store!
      this._vm.$store.dispatch('addUnreadNotification', notification).then(() => {
        this._vm.$root.$emit(`notification.${notification.type}`)
        this._vm.$root.$emit('notificationArrived')
        this._vm.notifyNotification(
          this._vm.$vs,
          notification.message(30),
          duration,
          () => { notification.onClick(this, notification) },
          position,
          title,
          icon
        )
      })
    } else {
      this.dlog(`The handled notification ${notification.type} doesnt exist!`)
    }
  }

  openNewTab(notification) {
    const clickOptions = ('clickOptions' in notification) ? notification.clickOptions : {}

    const ignoreMarkRead = ('ignoreMarkRead' in clickOptions)
    if (!ignoreMarkRead) this.markAsRead(notification)

    if (!this._vm.isEmpty(notification.link)) {
      if (notification && notification.link) {
        this._vm.$utils.browser.newTab(notification.link)
      }
    } else {
      this.dlog(` ${notification.type} has no link attribute defined.`)
    }
  }
}