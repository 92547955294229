import { MediaScreenRejectEvent, MediaScreenShareEvent, UserMediaRejectEvent, UserMediaShareEvent } from '@/models/events/MediaEvents'
import Bowser from 'bowser'
import VideoStreamMerger from 'video-stream-merger'
import Vue from 'vue'
import { VueConstructor } from 'vue/types/umd'
import { PROCTORING_STREAM_TYPE } from '@/util/Enums'

export default {
  install(Vue: VueConstructor) {

    // Proctoring
    Vue.prototype.enumerateDevices = (kind = null) => {
      const devicesFiltered = [] as Array<MediaDeviceInfo>
      return new Promise((resolve, reject) => {

        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
          console.log('enumerateDevices() not supported.')
          reject([])
        }

        navigator.mediaDevices.enumerateDevices().then((devices) => {
          if (kind === null) resolve(devices)
          for (let i = 0; i < devices.length; i++) {
            const device = devices[i]
            // console.log(`${device.kind  }: ${  device.label  } id = ${  device.deviceId}`)
            if (device.kind === kind) {
              devicesFiltered.push(device)
            }
          }
          resolve(devicesFiltered)
        })
      })
    }

    Vue.prototype.startScreenCapture = (displayMediaOptions, vm: any = null) => {
      const mediaDevices: MediaDevices = navigator.mediaDevices
      return new Promise((resolve, reject) => {
        // @ts-ignore
        mediaDevices.getDisplayMedia(displayMediaOptions).then(stream => {
          if (vm && '$root' in vm) {
            const event = new MediaScreenShareEvent(stream)
            vm.$root.$emit('mediaShare', event)
          }
          resolve(stream)
        }, error => {
          if (vm && '$vs' in vm && '$root' in vm) {
            const event = new MediaScreenRejectEvent(error)
            vm.$root.$emit('mediaReject', event)
          }
          reject(Vue.prototype.handleCaptureMediaError(error, vm, 'screen'))
        })
      })
    }

    Vue.prototype.startCaptureUserMedia = (constraits, vm: any = null, captureType = null, emitEvent = false) => {
      return new Promise((resolve, reject) => {
        return navigator.mediaDevices.getUserMedia(constraits).then(stream => {
          if (vm && '$root' in vm) {
            const event = new UserMediaShareEvent(stream)
            if (emitEvent) {
              vm.$root.$emit('mediaShare', event)
            }
          }
          resolve(stream)
        }, error => {
          if (vm && '$vs' in vm && '$root' in vm) {
            const event = new UserMediaRejectEvent({})
            if (emitEvent) {
              vm.$root.$emit('mediaReject', event)
            }
          }
          reject(Vue.prototype.handleCaptureMediaError(error, vm, captureType))
        })
      })
    }

    Vue.prototype.detectMediaRecorderPerformance = (stream: MediaStream, options: any) => {
      return new Promise((resolve, reject) => {


        const mediaRecorder = new MediaRecorder(stream, options)

        let startTime = 0
        const recordedBlobs: Array<Blob> = []
        mediaRecorder.ondataavailable = event => {
          if (event.data && event.data.size > 0) {
            recordedBlobs.push(event.data)
          }
        }

        mediaRecorder.onstart = function() {
          startTime = performance.now()
        }

        mediaRecorder.onstop = function() {

          const elapsedTime = performance.now() - startTime
          const fps = 1000 / (elapsedTime / recordedBlobs.length)
          console.log(`FPS: ${fps.toFixed(2)}`)

          if (fps < 30) {
            console.warn(`Performance de vídeo baixo: FPS de ${fps.toFixed(2)}`)
          }

          resolve({
            fps
          })
        }

        mediaRecorder.start(1000)
        setTimeout(() => {
          mediaRecorder.stop()
        }, 5000)

      })
    }

    Vue.prototype.proctoringCompability = () => {
      const browser = Bowser.getParser(window.navigator.userAgent)

      if (browser.getBrowserName() === 'Electron') {
        return true
      }

      const isValidBrowser = browser.satisfies({
        // or in general
        chrome: '>=66',
        firefox: '>=66',
        opera: '>=60',
        //edge: '>=79'
      })
      return isValidBrowser
    }

    Vue.prototype.isChrome = () => {
      const browser = Bowser.getParser(window.navigator.userAgent)
      return browser.getBrowserName().toLowerCase() === 'chrome'
    }

    Vue.prototype.isFirefox = () => {
      const browser = Bowser.getParser(window.navigator.userAgent)
      return browser.getBrowserName().toLowerCase() === 'firefox'
    }

    Vue.prototype.isOpera = () => {
      const browser = Bowser.getParser(window.navigator.userAgent)
      return browser.getBrowserName().toLowerCase() === 'opera'
    }

    Vue.prototype.isMacOS = () => {
      const browser = Bowser.getParser(window.navigator.userAgent)
      return browser.getOSName().toLowerCase() === 'macos'
    }

    Vue.prototype.isLinux = () => {
      const browser = Bowser.getParser(window.navigator.userAgent)
      return browser.getOSName().toLowerCase() === 'linux'
    }

    Vue.prototype.isWindows = () => {
      const browser = Bowser.getParser(window.navigator.userAgent)
      return browser.getOSName().toLowerCase() === 'windows'
    }

    Vue.prototype.OSName = () => {
      const browser = Bowser.getParser(window.navigator.userAgent)
      return browser.getOSName().toLowerCase()
    }

    Vue.prototype.browserName = () => {
      const browser = Bowser.getParser(window.navigator.userAgent)
      return browser.getBrowserName().toLowerCase()
    }

    Vue.prototype.browserVersion = () => {
      const browser = Bowser.getParser(window.navigator.userAgent)
      return browser.getBrowserVersion()
    }

    Vue.prototype.browserDownloadLink = (nav = 'firefox') => {
      //const browser = Bowser.getParser(window.navigator.userAgent)
      if (nav==='firefox') {
        return 'https://www.mozilla.org/pt-BR/firefox/download/thanks/'
      } else if (nav==='chrome') {
        return 'https://www.google.pt/intl/pt-PT/chrome/'
      } else if (nav==='opera') {
        return 'opera url'
      }
      return ''
    }

    Vue.prototype.mergeStreams = (stream1: MediaStream, stream2, MediaStream) => {

      const mediaSettings = stream1.getVideoTracks()[0].getSettings()
      const screenSettings = stream2.getVideoTracks()[0].getSettings()

      const mediaWidth = mediaSettings.width ? mediaSettings.width : 100
      const mediaHeight = mediaSettings.height ? mediaSettings.height : 100
      const screenWidth = screenSettings.width ? screenSettings.width : 100
      const screenHeight = screenSettings.height ? screenSettings.height : 100

      const cameraRatio = mediaHeight / screenHeight
      const missingPercent = 1.0 - cameraRatio
      let cameraFinalWidth = 0

      if (missingPercent < 0) {
        cameraFinalWidth = Math.abs(mediaWidth / (-1 + missingPercent))
      } else if (missingPercent >= 0) {
        cameraFinalWidth = (1 + missingPercent) * mediaWidth
      }

      const merger: any = new VideoStreamMerger(
        {
          width: cameraFinalWidth + screenWidth,
          height: screenHeight,
          clearRect: true,
          fps: 15
        }
      )

      // console.log('Media', mediaWidth, mediaHeight)
      // console.log('Screen', screenWidth, screenHeight)
      // console.log('Merger', merger.width, merger.height)
      // console.log(`Camera ratio: ${cameraRatio}  Missing Percent ${missingPercent} Camera Final Width ${cameraFinalWidth}`)

      merger.addStream(stream1, {
        x: 0,
        y: 0,
        width: cameraFinalWidth,
        height: screenHeight,
        mute: false
      })

      merger.addStream(stream2, {
        x: cameraFinalWidth,
        y: 0,
        width: screenWidth,
        height: screenHeight,
        mute: true
      })

      // Start the merging. Calling this makes the result available to us
      merger.start()
      return merger
    }

    Vue.prototype.stopStream = (stream: MediaStream) => {
      if (stream) {
        const tracks = stream.getTracks()
        if (tracks) {
          tracks.forEach(track => {
            track.stop()
            stream.removeTrack(track)
          })
        }

        const audioTracks = stream.getAudioTracks()
        if (audioTracks) {
          audioTracks.forEach(track => {
            track.stop()
            stream.removeTrack(track)
          })
        }

        const videoTracks = stream.getVideoTracks()
        if (videoTracks) {
          videoTracks.forEach(track => {
            track.stop()
            stream.removeTrack(track)
          })
        }
      }
    }

    Vue.prototype.handleCaptureMediaError = (err, vm: Vue, captureType) => {

      const browser = Bowser.getParser(window.navigator.userAgent)

      let settingMessage = `<b>Clique no link para abrir as configurações e dar permissão para o navegador ${browser.getBrowserName()}:</b>`
      if (captureType && Vue.prototype.isWindows()) {
        settingMessage += '<a href="ms-settings:privacy-webcam"> Câmera </a>'
        settingMessage += '<a href="ms-settings:privacy-microphone"> Microfone </a>'
        settingMessage += '<a href="ms-settings:privacy-broadcasting"> Gravação de Tela </a>'
      } else if (captureType && Vue.prototype.isMacOS()) {
        settingMessage += '<a href="x-apple.systempreferences:com.apple.preference.security?Privacy_Camera"> Câmera </a>'
        settingMessage += '- <a href="x-apple.systempreferences:com.apple.preference.security?Privacy_Microphone"> Microfone </a>'
        settingMessage += '- <a href="x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture"> Gravação de Tela </a>'
      } else if (captureType && Vue.prototype.isLinux()) {
        settingMessage = `Verifique se o seu navegador ${browser.getBrowserName()} possui permissão no seu sistema operacional para os dispositivos câmera, microfone e a gravação de tela.`
      }

      if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
        //required track is missing
        console.error(err)
        return 'Não foi possível encontrar microfone ou câmera disponível.'
      } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
        //webcam or mic are already in use
        console.error(err)
        return 'Sua câmera ou microfone já estão em uso.'
      } else if (err.name === 'OverconstrainedError' || err.name === 'ConstraintNotSatisfiedError') {
        //constraints can not be satisfied by avb. devices
        console.error(err)
        return 'Constraints de GetMediaDevice não são satisfatórias.'
      } else if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
        //permission denied in browser
        console.error(err)
        return settingMessage
      } else if (err.name === 'TypeError' || err.name === 'TypeError') {
        //empty constraints object
        console.error(err)
        return 'Não é possível buscar dispositivos de mídia com constraints vazias.'
      } else {
        //other errors
        console.error(err)
        return `Não foi possível utilizar este dispositivo: ${err}`
      }
    }

    Vue.prototype.forceFullscreen = () => {
      try {
        const electron = window.require('electron')
        const remote = electron.remote
        if (remote) {
          const currentWindow = electron.remote.getCurrentWindow()
          currentWindow.setKiosk(true)
          currentWindow.setAlwaysOnTop(true)
          currentWindow.setVisibleOnAllWorkspaces(true)
        }
      } catch (error) {
        console.log(error)
      }
    }

    Vue.prototype.exitFullScreen = () => {
      try {
        const electron = window.require('electron')
        const remote = electron.remote
        if (remote) {
          const currentWindow = remote.getCurrentWindow()
          currentWindow.setKiosk(false)
          currentWindow.setAlwaysOnTop(false)
          currentWindow.setVisibleOnAllWorkspaces(false)
        }
      } catch (error) {
        console.log(error)
      }
    }

    Vue.prototype.playPhoneRing = () => {
      new Audio(require('@/assets/sounds/ring.wav')).play()
    }

    Vue.prototype.isProctoringRecord = (recordMedium) => {
      return recordMedium === PROCTORING_STREAM_TYPE.Record
        || recordMedium === PROCTORING_STREAM_TYPE.LiveRecord
          || recordMedium === PROCTORING_STREAM_TYPE.LiveChatRecord
    }

    Vue.prototype.getRecordMimeType = () => {

      let options = {}

      if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
        options = {mimeType: 'video/webm;codecs=vp9'}
      } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8,opus')) {
        options = {mimeType: 'video/webm;codecs=vp8,opus'}
      } else {
        options = {mimeType: 'video/webm'}
      }
      return options
    }

    Vue.prototype.isStreamFullScreen = (stream: MediaStream) => {

      const track = stream.getVideoTracks()[0]
      const settings: any = track.getSettings()
      console.log('track.getSettings().displaySurface...', track.getSettings())
      if ('displaySurface' in settings) {
        return settings.displaySurface === 'monitor'
      } else {
        return true // Return true when the browser don't support displaySurface
      }

    }
  }

}
