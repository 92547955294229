import Vue from 'vue'
import router from '@/router'
import store from '@/store/store'

export function setActiveInstitution(institution, acl) {
  return store
    .dispatch(
      'institution/selectActiveInstitution',
      institution && institution.id
    )
    .then((value) => {
      let permissions: any = null
      if (institution) {
        permissions = institution.permissions
        acl.setPermissions(institution.permissions)

        // Update theme
        if (institution && institution.organization && institution.organization.custom_data) {
          const customData = JSON.parse(institution.organization.custom_data)
          if (customData && customData.primaryColor) {
            store.commit('UPDATE_PRIMARY_COLOR', customData.primaryColor)
            router.app.$vs.theme({ primary: customData.primaryColor });
          }
        }

      } else {
        const userInfo: string | null = localStorage.getItem('userInfo')
        if (userInfo) {
          permissions = JSON.parse(userInfo).permissions
          acl.setPermissions(permissions)
        }
      }

      if (
        router.currentRoute.name !== 'home' &&
        router.currentRoute.meta &&
        router.currentRoute.meta.rule
      ) {
        const currentRoutePermissions = router.currentRoute.meta.rule.split('|')
        let found = false
        const permit = permissions.find((permission) => {
          currentRoutePermissions.forEach((routePermission) => {
            if (permission.name === routePermission) {
              found = true
            }
          })
          return found
        })
        if (!permit) router.push('/')
      }

      return true
    })
}
