/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import auth from '@/auth/authService'

export default {
  isUserLoggedIn: () => {
    let userInfo: any = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    if (userInfo === null) return false
    return 'token' in userInfo
  }
}