/*=========================================================================================
  File Name: i18n.js
  Description: i18n configuration file. Imports i18n data.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18nData from './i18nData'

Vue.use(VueI18n)

const nav: any = navigator
let lang: string = nav.language || nav.userLanguage

if (lang) {
  if (lang.startsWith('pt-')) {
    lang = 'pt'
  } else if (lang.startsWith('en-')) {
    lang = 'en'
  } else if (lang.startsWith('es-')) {
    lang = 'es'
  } else if (lang.startsWith('fr-')) {
    lang = 'fr'
  }
}

const i18n = new VueI18n({
  locale: lang, // set default locale
  messages: i18nData,
  silentTranslationWarn: true
})

export default i18n
