/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList'
import themeConfig, { colors } from '@/../themeConfig'
import {newUserInfo} from '@/util/Constants'

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// *From Auth - Data will be received from auth provider
// const userDefaults = {
//   uid: 0, // From Auth
//   displayName: 'John Doe', // From Auth
//   // photoURL: require('@/assets/images/portrait/small/avatar-s-6.jpg'), // From Auth
//   status: 'online',
//   userRole: 'admin'
// }

const userInfo: any = localStorage.getItem('userInfo')
const userInfoLocalStorage = JSON.parse(userInfo) || newUserInfo()

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getUserInfo = () => {
  const userInfo = {name: '', phone: ''}

  // Update property in user
  // Object.keys(userDefaults).forEach(key => {
  //   // If property is defined in localStorage => Use that
  //   userInfo[key] = userInfoLocalStorage[key] ? userInfoLocalStorage[key] : null
  // })

  // Include properties from localStorage  
  Object.keys(userInfoLocalStorage).forEach(key => {
    if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null)
      userInfo[key] = userInfoLocalStorage[key]
  })

  return userInfo
}

const getUserPerson = () => {
  const userInfo: any = getUserInfo()
  if (userInfo.people && userInfo.people.length >= 0) {
    return userInfo.people[0] || {}
  }
  return {}
}

const getActiveRole = () => {
  const userInfo: any = getUserInfo()
  if (userInfo.roles && userInfo.roles.length >= 1) {
    const isTeacher = userInfo.roles && userInfo.roles.find( (role) => {
      return role.name === 'teacher'
    })
    return isTeacher || userInfo.roles[0] || {name: 'none'}
  } else if(userInfo.roles && userInfo.length === 1) {
    return userInfo.roles[0] || {name: 'none'}
  }
  return {name: 'none'}
}

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = function(query) {
    return window.matchMedia(query).matches
  }

  // if ('ontouchstart' in window || window.DocumentTouch) {
  if ('ontouchstart' in window) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  AppActiveUser: getUserInfo(),
  activePerson: getUserPerson(),
  activeRole: getActiveRole(),
  unreadNotifications: [],
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  is_touch_device: is_touch_device(),
  mainLayoutType: themeConfig.mainLayoutType || 'vertical',
  navbarSearchAndPinList,
  reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: 'default',
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  starredPages: navbarSearchAndPinList['pages'].data.filter(
    page => page.is_bookmarked
  ),
  theme: themeConfig.theme || 'light',
  themePrimaryColor: '#0011ff', // Current theme color
  defaultThemePrimaryColor: '#0011ff', // Color default when no institution is selected
  serverOnline: true,
  lastRequest: 0,
  internetConnection: true,
  pingInterval: 60, //default state for pingInterval, it is changed at every logged that cameth from router.ts
  slowKbpsRate: 30,
  frozenRouter: false,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
  windowHeight: null,
  packageVersion: process.env.PACKAGE_VERSION || '0',
}

export default state
