/* eslint-disable no-mixed-operators */
import store from '@/store/store'
import { get, isArray } from 'lodash'
import moment from 'moment'

const mimeTypes = {
  a: 'application/octet-stream',
  ai: 'application/postscript',
  aif: 'audio/x-aiff',
  aifc: 'audio/x-aiff',
  aiff: 'audio/x-aiff',
  au: 'audio/basic',
  avi: 'video/x-msvideo',
  bat: 'text/plain',
  bin: 'application/octet-stream',
  bmp: 'image/x-ms-bmp',
  c: 'text/plain',
  cdf: 'application/x-cdf',
  csh: 'application/x-csh',
  css: 'text/css',
  dll: 'application/octet-stream',
  doc: 'application/msword',
  docx:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  dot: 'application/msword',
  dvi: 'application/x-dvi',
  eml: 'message/rfc822',
  eps: 'application/postscript',
  etx: 'text/x-setext',
  exe: 'application/octet-stream',
  gif: 'image/gif',
  gtar: 'application/x-gtar',
  h: 'text/plain',
  hdf: 'application/x-hdf',
  htm: 'text/html',
  html: 'text/html',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  js: 'application/x-javascript',
  ksh: 'text/plain',
  latex: 'application/x-latex',
  m1v: 'video/mpeg',
  man: 'application/x-troff-man',
  me: 'application/x-troff-me',
  mht: 'message/rfc822',
  mhtml: 'message/rfc822',
  mif: 'application/x-mif',
  mov: 'video/quicktime',
  movie: 'video/x-sgi-movie',
  mp2: 'audio/mpeg',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  mpa: 'video/mpeg',
  mpe: 'video/mpeg',
  mpeg: 'video/mpeg',
  mpg: 'video/mpeg',
  ms: 'application/x-troff-ms',
  nc: 'application/x-netcdf',
  nws: 'message/rfc822',
  o: 'application/octet-stream',
  obj: 'application/octet-stream',
  oda: 'application/oda',
  pbm: 'image/x-portable-bitmap',
  pdf: 'application/pdf',
  pfx: 'application/x-pkcs12',
  pgm: 'image/x-portable-graymap',
  png: 'image/png',
  pnm: 'image/x-portable-anymap',
  pot: 'application/vnd.ms-powerpoint',
  ppa: 'application/vnd.ms-powerpoint',
  ppm: 'image/x-portable-pixmap',
  pps: 'application/vnd.ms-powerpoint',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.ms-powerpoint',
  ps: 'application/postscript',
  pwz: 'application/vnd.ms-powerpoint',
  py: 'text/x-python',
  pyc: 'application/x-python-code',
  pyo: 'application/x-python-code',
  qt: 'video/quicktime',
  ra: 'audio/x-pn-realaudio',
  ram: 'application/x-pn-realaudio',
  ras: 'image/x-cmu-raster',
  rdf: 'application/xml',
  rgb: 'image/x-rgb',
  roff: 'application/x-troff',
  rtx: 'text/richtext',
  sgm: 'text/x-sgml',
  sgml: 'text/x-sgml',
  sh: 'application/x-sh',
  shar: 'application/x-shar',
  snd: 'audio/basic',
  so: 'application/octet-stream',
  src: 'application/x-wais-source',
  swf: 'application/x-shockwave-flash',
  t: 'application/x-troff',
  tar: 'application/x-tar',
  tcl: 'application/x-tcl',
  tex: 'application/x-tex',
  texi: 'application/x-texinfo',
  texinfo: 'application/x-texinfo',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  tr: 'application/x-troff',
  tsv: 'text/tab-separated-values',
  txt: 'text/plain',
  ustar: 'application/x-ustar',
  vcf: 'text/x-vcard',
  wav: 'audio/x-wav',
  wiz: 'application/msword',
  wsdl: 'application/xml',
  xbm: 'image/x-xbitmap',
  xlb: 'application/vnd.ms-excel',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xml: 'text/xml',
  xpdl: 'application/xml',
  xpm: 'image/x-xpixmap',
  xsl: 'application/xml',
  xwd: 'image/x-xwindowdump',
  zip: 'application/zip',
  ico: 'image/x-icon'
}

export const mimeType = function(mimeType: string) {
  for (const key in mimeTypes) {
    if (mimeTypes[key] === mimeType) return key
  }
  return null
}

export const popupCenter = function(url, title, w, h, opts) {

  const y = window.outerHeight / 2 + window.screenY - (h / 2)
  const x = window.outerWidth / 2 + window.screenX - (w / 2)
  const newWindow = window.open(url, title, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${  w  }, height=${  h  }, top=${  y  }, left=${  x}`)

  if (newWindow) {
    newWindow.focus()
  }

  return newWindow
}

export const isUserLoggedIn = () => {
  let userInfo: any = localStorage.getItem('userInfo')
  userInfo = JSON.parse(userInfo)
  if (userInfo === null) return false
  return 'token' in userInfo
}

export const responseMessagesHasErrorCode = (code: Number, messages: Array<string>) => {
  return messages.length !== 0 && !!messages[0].startsWith(`E0x${code.toString(16).toUpperCase()}`)
}

export const isNormalInteger = (str) => {
  return /^\+?(0|[1-9]\d*)$/.test(str)
}

export const arraySortDate = (prop, sign = 1) => (a, b) => {
  const aDatestring = get(a, prop, null)
  const bDatestring = get(b, prop, null)

  if (aDatestring && bDatestring) {
    const aDate = moment(aDatestring)
    const bDate = moment(bDatestring)

    return Math.sign(aDate.diff(bDate)) * sign
  } else return 0
}

export const arraySort = (prop, sign = 1, useGet = false) => (a, b) => {
  let first = ''
  let second = ''
  if (useGet) {
    first = String(get(a, prop, '')).toUpperCase()
    second = String(get(b, prop, '')).toUpperCase()
  } else {
    first = a[prop].toUpperCase() // ignore upper and lowercase
    second = b[prop].toUpperCase() // ignore upper and lowercase
  }

  if (first < second) {
    return -1 * sign // a negative number if the first element should go before the second, in this case it stays
  }
  if (first > second) {
    return 1 * sign // a positive number if the first element should go after the second
  }

  // if 0 then do nothing
  return 0
}

/**
 * This function verifies in the frontend only if the currently logged user has an admin role.
 */
export function isAdmin() {
  /**
   * Gets the roles from the store and use that
   * for verification instead of acl.hasPermission('general_system_admin')
   * because when the user changes active institution it will replace all
   * permissions inside acl.
   */
  const roles = store.getters['userRoles']

  if (roles && isArray(roles)) {
    return roles.some((role) => role.name === 'admin')
  }

  return false
}

export function getTextReadableTime(text: string, noticeTime:number = 1000) {
  // A word
  const tokens = text.split(' ')

  // Base word milliseconds to read.
  // Which means that an average word of AverageComplexity characters will take 480 ms to read.
  const timePerToken = 230

  // Average word complexity, basically the amount of characters a word might have in average.
  const averageTokenComplexity = 4

  // The final and total time in milliseconds the given text will probably be taken to be read.
  let time = 0

  tokens.forEach((token) => {
    const tokenComplexity = token.length

    // We increase or decrease the time per token if its complexity is bigger or lower than 4.
    time += timePerToken * (tokenComplexity / averageTokenComplexity)
  })

  return time + noticeTime
}

export function getSubdomain() {
  const host = window.location.host
  console.log('host', host)
  const parts = host.split('.')
  const subdomain: string = parts[0]

  if (subdomain.startsWith('0.0.0.0')) {
    return '0.0.0.0'
  } else if (subdomain.startsWith('localhost')) {
    return 'localhost'
  }

  return subdomain
}

export function currencyFormatter(num:number = 0, curDisplay = 'symbol'){
  let currencyFmt = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    currencyDisplay: curDisplay
  })
  return currencyFmt.format(num)
}

export function currencyFormatterWithoutSymbol(num:number = 0){
  let n = currencyFormatter(num).replace('R$', '').replace(/\s/g, '')
  return n
}
