import Moment from 'moment'
export abstract class SerializableEvent {
  event: any
  type = ''
  emitted_at: string
  
  constructor(event) {
    this.event = event
    this.emitted_at = Moment().format()
  }

  serialize() {
    throw 'The class extending SerializableEvent must implement its own serialize method.'
  }
}