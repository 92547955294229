import { mimeType } from '@/util/Util'
import { getCurrentBreakpoint } from '@/util/TailwindBreakpointUtil'
import { BREAKPOINT_ORDER } from '@/util/Constants'
import Bowser from 'bowser'
import { isArray, isString } from 'lodash'
import { PROCTORING_ENV_RESTRICTION } from '@/util/Enums'
import Vue from 'vue'
import i18n from '@/i18n/i18n'
import FastSpeedtest from 'fast-speedtest-api'

interface Window {
  wiris: Object
}

declare let window: any
declare let com: any

//TODO transform utils into class. move this into it. simplify whats happening inside install(Vue)
const whenElementExists = (selector: any, callback: Function, doesntExist: Function = () => {}) => {
  const element = document.querySelector(selector)
  if (element) {
    return callback(element)
  } else {
    console.debug('Queried selector "', selector, '" doesnt exist.')
    return doesntExist(selector)
  }
}

export default {
  install(Vue: any) {

    /* Each util function in $utils is
    * categorized through its parent object.
    * The idea is to convert all the functions in this file,
    * to inside this structured object. TODO
    */
    Vue.prototype.$utils = {
      browser : {
        getScrollPercent: () => {
          const documentElement = document.documentElement
          return (
            documentElement['scrollTop'] || document.body['scrollTop']
          ) / (
            (
              documentElement['scrollHeight'] || document.body['scrollHeight']
            ) - documentElement.clientHeight
          ) * 100;
        },
        getMaxScroll: () => {
          return Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
          )
        },
        getCurrentScroll: () => {
          return window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop
        },
        newTab: (link: string): Object => {
          const tabRef = window.open()
          tabRef.location = link
          return tabRef
        },
        appNewTab: (vm: { $router: { resolve: (arg0: { path: any }) => any } }, path: any) => {
          const route = vm.$router.resolve({path: path})
          Vue.prototype.$utils.browser.newTab(route.href)
        },
        confirmationDialog: (vs: { dialog: (arg0: { type: string; color: string; title: string; acceptText: string; cancelText: string; text: any; accept: any; cancel: undefined; close: undefined }) => any }, message: any, accept: any, cancel = undefined, close = undefined) => {
          return vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: `${i18n.t('atencao')}`,
            acceptText: `${i18n.t('sim')}`,
            cancelText: `${i18n.t('nao')}`,
            text: message,
            accept,
            cancel,
            close
          })
        },
        // Safe method for getting ONE element by a class name
        getElementByClass: (classes: string) => {
          const elements = document.getElementsByClassName(classes)
          if ('length' in elements && elements.length > 0) {
            if (elements.length > 1) {
              console.warn(`Tried to get element by class "${classes}", there is more than one, but only one is returned.`)
            }

            return elements[0]
          } else {
            console.warn(`Tried to get element by class "${classes}" but there are none...`)
          }

          return null
        },
        addVisibilityChange: (onchange: EventListenerOrEventListenerObject) => {
          let hidden = 'hidden'

          if (hidden in document) {
            document.addEventListener('visibilitychange', onchange)
          } else if ((hidden = 'mozHidden') in document) {
            document.addEventListener('mozvisibilitychange', onchange)
          } else if ((hidden = 'webkitHidden') in document) {
            document.addEventListener('webkitvisibilitychange', onchange)
          } else if ((hidden = 'msHidden') in document) {
            document.addEventListener('msvisibilitychange', onchange)
            // IE 9 and lower:
            // else if ('onfocusin' in document)
            //   document.onfocusin = document.onfocusout = onchange
            // All others:
          } else {
            window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange
          }
        },
        addDevToolsChange: (eventCallback: (arg0: boolean, arg1: string | undefined) => void) => {

          const devToolsFunc = () => {

            const devtools: any = {
              isOpen: false,
              orientation: undefined
            }

            const threshold = 160
            const widthThreshold = window.outerWidth - window.innerWidth > threshold
            const heightThreshold = window.outerHeight - window.innerHeight > threshold
            const orientation = widthThreshold ? 'vertical' : 'horizontal'

            if (
              !(heightThreshold && widthThreshold) &&
              ((window.Firebug && window.Firebug.chrome && window.Firebug.chrome.isInitialized) || widthThreshold || heightThreshold)
            ) {
              if ((!devtools.isOpen || devtools.orientation !== orientation) && eventCallback) {
                eventCallback(true, orientation)
              }

              devtools.isOpen = true
              devtools.orientation = orientation
            } else {
              if (devtools.isOpen && eventCallback) {
                eventCallback(false, undefined)
              }

              devtools.isOpen = false
              devtools.orientation = undefined
            }
          }

          setInterval(devToolsFunc, 500)
        },
        fullScreenCompability: () => {
          const browser = Bowser.getParser(window.navigator.userAgent)

          if (browser.getBrowserName() === 'Electron') {
            return true
          }

          const isValidBrowser = browser.satisfies({
            chrome: '>=71',
            firefox: '>=64',
            opera: '>=64',
            mobile: {
              firefox: '>=89'
            },
          })
          return isValidBrowser ? isValidBrowser : false
        },
        isAtleastChrome: (version: any) => {
          const browser = Bowser.getParser(window.navigator.userAgent)
          const satisfaction = {
            chrome: `>=${version}`
          }
          return !!browser.satisfies(satisfaction)
        },
        detectConnectionSpeedTest(success, error) {
          const speedtest = new FastSpeedtest({
            token: 'YXNkZmFzZGxmbnNkYWZoYXNkZmhrYWxm', // required
            verbose: false, // default: false
            timeout: 10000, // default: 5000
            https: true, // default: true
            urlCount: 5, // default: 5
            bufferSize: 8, // default: 8
            unit: FastSpeedtest.UNITS.Mbps, // default: Bps
          })

          speedtest.getSpeed().then(s => {
            success(s)
          }).catch(e => {
            error(e.message)
          })
        },
        detectConnectionSpeed(url: any) {

          if (url === null || url === undefined) {
            url = `${process.env.VUE_APP_DOWNLOAD_TEST_URL}_1.jpg`
          }

          return new Promise((resolve, reject) => {

            // Let's initialize the primitives
            // eslint-disable-next-line init-declarations, prefer-const
            let endTime: number, fileSize: number

            let startTime = (new Date()).getTime()

            // Set up the AJAX to perform
            const xhr = new XMLHttpRequest()

            // Rig the call-back... THE important part
            xhr.onreadystatechange = function () {

              // we only need to know when the request has completed
              if (xhr.readyState === 4) {

                // Here we stop the timer & register end time
                endTime = (new Date()).getTime()

                // Also, calculate the file-size which has transferred
                fileSize = xhr.response.length
                // N.B: fileSize reports number of Bytes

                // Calculate the connection-speed
                const elapsedTime = endTime - startTime
                const speed = ((fileSize * 8) / (elapsedTime / 1000) / 1024 / 1024).toFixed(2)

                const duration = (endTime - startTime) / 1000
                const bitsLoaded = xhr.response.length * 8
                const speedBps = (bitsLoaded / duration).toFixed(2)
                const speedKbps = (+speedBps / 1024).toFixed(2)
                const speedMbps = (+speedKbps / 1024).toFixed(2)

                console.log('speedMbps...', speedMbps)


                // Report the result, or have fries with it...
                resolve({
                  fileSize: fileSize,
                  speed: speedMbps,
                  elapsedTime: elapsedTime
                })
              }
            }

            xhr.open('GET', url)
            xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate')
            xhr.setRequestHeader('Pragma', 'no-cache')
            xhr.setRequestHeader('Expires', '0')
            startTime = (new Date()).getTime()
            xhr.send()
          })
        },
        detectUploadSpeed(dataSize: number = 1024) {
          return new Promise((resolve, reject) => {

            const http = new XMLHttpRequest()
            let startTime = 0, endTime = 0
            const url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/upload_speed_test`
            //const url = 'https://api.totalprepexam.com/api/v1/upload_speed_test'
            let data = 'd=' // the raw data you will send

            const formData: any = new FormData()

            for (let i = 0; i < (dataSize - 2); i++) { //if you want to send 1 kb (2 + 1022 bytes = 1024b = 1kb). change it the way you want
              data += 'k'
            }

            formData.append('fileName', data)

            http.open('POST', url, true)

            http.setRequestHeader('Content-type', 'multipart/form-data')
            http.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate')
            http.setRequestHeader('Pragma', 'no-cache')
            http.setRequestHeader('Expires', '0')

            http.onreadystatechange = () => {
              if (http.readyState === 4 && http.status === 200) {
                endTime = (new Date()).getTime()
                const elapsedTime = endTime - startTime
                const speedMbps = ((data.length * 8) / (elapsedTime / 1000) / 1024 / 1024).toFixed(2)
                resolve({
                  speed: speedMbps,
                  elapsedTime: elapsedTime,
                  dataSize: dataSize
                })
              }
            }

            http.onerror = (e) => {
              reject(e)
            }

            startTime = (new Date()).getTime()
            http.send(formData)

          })
        },
        openDesktopApp(short_url_id: any, callback: (arg0: boolean) => void) {
          try {
            window.location = `educatena://form/${short_url_id}`
            if (callback) callback(true)
          // eslint-disable-next-line no-empty
          } catch (e) {
            if (callback) callback(false)
          }
        },
        speak: (text, lang = 'pt-BR', rate = 1, pitch = 1, volume = 1) => {
          // create a SpeechSynthesisUtterance to configure the how text to be spoken
          let speakData = new SpeechSynthesisUtterance();
          speakData.volume = volume; // From 0 to 1
          speakData.rate = rate; // From 0.1 to 10
          speakData.pitch = pitch; // From 0 to 2
          speakData.text = text;
          speakData.lang = lang;

          const voices = speechSynthesis.getVoices()
          const voice = voices.filter(voice => {
            return voice.lang === lang && voice.voiceURI === 'Google português do Brasil'
          })

          if (voice && voice.length > 0) {
            speakData.voice = voice[0]  // speechSynthesis.getVoices()[22];
          }

          setTimeout(() => { // speak after 2 seconds
            window.speechSynthesis.speak(speakData)
          }, 500)
        },
        getDefaultLocale() {
          const nav: any = navigator
          let lang: string = nav.language || nav.userLanguage

          if (lang) {
            if (lang.startsWith('pt-')) {
              lang = 'pt'
            } else if (lang.startsWith('en-')) {
              lang = 'en'
            }
          }

          return lang
        }
      },
      DOM: {
        measure: {
          elementHeight: (selector: any) => {
            return whenElementExists(selector, (element: { getBoundingClientRect: () => { (): any; new(): any; height: any } }) => {
              return element.getBoundingClientRect().height
            })
          },
          elementWidth: (selector: any) => {
            return whenElementExists(selector, (element: { getBoundingClientRect: () => { (): any; new(): any; width: any } }) => {
              return element.getBoundingClientRect().width
            })
          }
        },
        whenElementExists: whenElementExists
      },
      format : {
        featherIcon: {
          fromNotify: (icon: string) => {
            const iconStr = icon.split('-').map((token: string, index: number, tokens: any) => {
              if (index > 0) {
                return `${token.slice(0, 1).toUpperCase()}${token.slice(1)}`
              }
            })
            if (iconStr.length > 0) {
              iconStr.push('Icon')
            }
            return iconStr.toString().replaceAll(',', '')
          }
        },
        vsButtonMaterialIcon: {
          fromQuestionType: (questionType: any) => {
            switch (questionType) {
            case 'simple_text':
              return 'edit'
            case 'multiple_choice':
              return 'list'
            case 'check_box':
              return 'check_box'
            default:
              return 'error'
            }
          }
        },
        component: {
          fromQuestionType: (question_type: String) => {
            const componentRadix = question_type.split('_').map(
              (el) => el.charAt(0).toUpperCase() + el.slice(1)
            ).join('')
            return {
              edit: componentRadix,
              show: `${componentRadix  }Show`,
              answer: `${componentRadix  }Answer`
            }
          }
        },
        firebaseUser: {
          fromDatabaseUser: (user: { id: any; profile_picture: any; name: any }) => {
            return {
              _id: user.id,
              avatar: user.profile_picture,
              username: user.name
            }
          }
        },
        date: (dateString: any, withTime = true) => {
          if (Vue.prototype.isEmpty(dateString)) return '-'
          const yearFormat = 'DD/MM/YYYY'
          const timeFormat = 'kk:mm'
          const format = `${yearFormat}${withTime ? ` ${timeFormat}` : ''}`
          return Vue.prototype.$moment(dateString).format(format)
        },
        longDate: (dateString: any) => {
          return Vue.prototype.$moment(dateString).format('dddd, D [de] MMMM [de] YYYY')
        },
        longDateWithTime: (dateString: any, textWhenBlankDate : string) => {
          return dateString ?
            Vue.prototype.$moment(dateString).format('dddd, D [de] MMMM [de] YYYY[,] kk:mm')
            : textWhenBlankDate ? i18n.t(textWhenBlankDate) : ''
        },
        dateWithFormat: (dateString: any, formatString: any) => {
          return Vue.prototype.$moment(dateString).format(formatString)
        },
        seconds: {
          humanReadableDuration: (vm: { $moment: { duration: (arg0: any, arg1: string) => any } }, seconds: any) => {
            const duration = vm.$moment.duration(seconds, 'seconds')
            const hoursUnit = duration.get('hours')
            const minutesUnit = duration.get('minutes')
            const secondsUnit = duration.get('seconds')
            let humanized = ''
            if (hoursUnit > 0) {
              humanized = `${humanized} ${hoursUnit}h `
            }
            if (minutesUnit > 0) {
              humanized = `${humanized} ${minutesUnit}min `
            }
            if (secondsUnit > 0) {
              humanized = `${humanized} ${secondsUnit}s`
            }

            return humanized
          }
        }
      },
      text: {
        emailTruncate: (email: string, prefixLength = 18, suffixLength = 13) => {
          if (email) {
            const emails = email.split('@')
            if (emails.length > 0) {
              const prefix = Vue.prototype.truncate(emails[0], prefixLength)
              const suffix = emails.length > 1 ? Vue.prototype.truncate(emails[1], suffixLength) : ''
              return `${prefix}@${suffix}`
            }
          }
          return ''
        }
      },
      reactive: {
        ratios: (any: null, small = null, medium = null, large = null) => {
          small = small ? small : any
          medium = medium ? medium : any
          large = large ? large : any
          const breakpoint = getCurrentBreakpoint()
          return (breakpoint === null || breakpoint === 'sm') ?
            small :
            breakpoint === 'md' ?
              medium :
              breakpoint === 'lg' ?
                large :
                any
        },
        colsForTotal: (total: number, max:Number|null = null, min:Number|null = null) => {
          const planeRoot = Math.ceil(Math.sqrt(total))
          max = max || 12
          min = min || 1
          const maxGated = (planeRoot <= max) ? planeRoot : max
          return maxGated < min ? min : maxGated
        },
        breakpointColsMax: (scale = 1.0, breakpoint:string|null) => {
          const currentBreakpoint = breakpoint || getCurrentBreakpoint()
          switch (currentBreakpoint) {
          case null: return Math.ceil(1 * scale)
          case 'sm': return Math.ceil(2 * scale)
          case 'md': return Math.ceil(3 * scale)
          case 'lg': return Math.ceil(4 * scale)
          case 'xl': return Math.ceil(5 * scale)
          case '2xl': return Math.ceil(6 * scale)
          default:
            break
          }
        },
        breakpointColsMin: (scale = 1.0, breakpoint:string|null) => {
          const currentBreakpoint = breakpoint || getCurrentBreakpoint()
          switch (currentBreakpoint) {
          case null: return Math.ceil(1 * scale)
          case 'sm': return Math.ceil(2 * scale)
          case 'md': return Math.ceil(3 * scale)
          case 'lg': return Math.ceil(4 * scale)
          case 'xl': return Math.ceil(4 * scale)
          case '2xl': return Math.ceil(5 * scale)
          default:
            break
          }
        }
      },
      validation: {
        number: (str: string) => {
          return /^[0-9]+$/.test(str)
        },
        email: (email: string) => {
          return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        }
      },
      serverError: {
        isCode: (code: any, error: { data: string }) => {
          const errorData = error && error.data && JSON.parse(error.data)
          try {
            errorData.messages = JSON.parse(errorData.messages)
          } catch (e) {
            console.error('Cant parse error messages')
          }
          return errorData && errorData.code === code && errorData
        },
        onCodeDo: (code: any, error: any, callBack: (arg0: any) => void, elseCallback = (error: any) => {}) => {
          const isCode = Vue.prototype.$utils.serverError.isCode(code, error)
          if (isCode) {
            callBack(isCode)
          } else {
            elseCallback(error)
          }
        },
      },
      array: {
        allLike: (predicate: (arg0: any) => any, haystack: any[]) => {
          const duplicates: Array<any> = []
          haystack.forEach((item: any) => {
            if (predicate(item)) {
              duplicates.push(item)
            }
          })
          return duplicates
        },
        uniqBy: (a: any[], key: (arg0: any) => any) => {
          const seen: any = {}
          return a.filter(function(item: any) {
            const k = key(item)
            return seen.hasOwnProperty(k) ? false : (seen[k] = true)
          })
        },
      },
      vue: {
        /**
         *
         * @param parent Vue vm where to search for the child component by ref string.
         * @param ref The ref string indentifying the child component.
         * @param foundCallback This will run if the child is found.
         * @param notFoundCallback This will run if the child is not found.
         */
        childDo: (parent: Vue, ref: string, foundCallback: (child: Vue) => any, notFoundCallback?: (parent: Vue) => any) : any => {
          const child = parent.$refs[ref]
          if (child) {
            return foundCallback(child as Vue)
          } else if (notFoundCallback) return notFoundCallback(parent)
          else return null
        }
      },
    }

    Vue.prototype.formatFloat = (val: string, places = 2) => {
      return parseFloat(val).toFixed(places)
    }

    Vue.prototype.devLog = (domain : string, ...thing: any) => {
      if (process.env.NODE_ENV === 'development') {
        console.debug(`${(new Date()).toLocaleString()}|${domain.toUpperCase()}|-> `, ...thing)
      }
    }

    Vue.prototype.showErrors = (validator: any, error: any) => {
      if (error.data) {
        const data = JSON.parse(error.data)
        const errors = data.errors
        if (errors) {
          Object.keys(errors).forEach(key => {
            validator.errors.add({
              field: key,
              msg: errors[key][0]
            })
          })
        }
      }
    }

    Vue.prototype.notifyErrors = (vs: any, error: any, time = 2000) => {
      if (error.data) {
        const data = JSON.parse(error.data)
        const errors = data.errors
        if (errors) {
          Object.keys(errors).forEach(key => {
            if (key in errors) {
              vs.notify({
                time: time,
                title: i18n.t('error'),
                text: errors[key][0],
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger',
                position: 'op-center'
              })
            }
          })
        }
      }
    }

    Vue.prototype.clearErrors = (validator: any) => {
      validator.errors.clear()
    }

    Vue.prototype.notifyError = (
      vs: any,
      message: String,
      time = 2000,
      position = 'top-center'
    ) => {
      vs.notify({
        time,
        title: i18n.t('error'),
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
        position: position
      })
    }

    Vue.prototype.notifySuccess = (
      vs: any,
      message: String = '',
      time = 2000,
      position = 'top-center'
    ) => {
      const successMessage =
        message !== '' ? message : i18n.t('operacao-realizada-com-sucesso')
      vs.notify({
        time,
        title: `${i18n.t('sucesso')}!`,
        text: successMessage,
        color: 'success',
        iconPack: 'feather',
        position: position,
        icon: 'icon-check-circle'
      })
    }

    Vue.prototype.notifyWarning = (
      vs: any,
      message: String = '',
      time = 2000,
      position = 'bottom-right',
      title = i18n.t('atencao-0'),
      callback: any
    ) => {
      const alertMessage = message !== '' ? message : 'Alerta.'
      const fixed = !time
      vs.notify({
        time,
        title: title,
        text: alertMessage,
        color: 'warning',
        iconPack: 'feather',
        position: position,
        icon: 'icon-alert-circle',
        click: callback,
        fixed: fixed
      })
    }

    Vue.prototype.notifyNotification = (
      vs: any,
      message: String = '',
      time = 2000,
      callback: any,
      position = 'top-center',
      title = `${i18n.t('notificacao')}!`,
      icon = 'icon-bell',
    ) => {
      const alertMessage = message !== '' ? message : 'Alerta.'
      vs.notify({
        time,
        title: title,
        text: alertMessage,
        color: 'success',
        iconPack: 'feather',
        position: position,
        icon: icon,
        click: callback
      })
    }

    Vue.prototype.mimeType = (type: string) => {
      return mimeType(type)
    }

    Vue.prototype.clearDashDots = (naturalEntituNumber: string) => {
      return naturalEntituNumber.replace(/\./g, '').replace('-', '')
    }

    Vue.prototype.isEmpty = (object: string | null | undefined) => {
      object = isString(object) ? object.trim() : object
      return (
        object === null || // The object is null ?
        object === '' || // The object is an empty string ?
        object === undefined || // The object is undefined ?
        (
          isArray(object) && // Or is the object an array and...
            object.length === 0 // Its length is zero, contains no elements.
        )
      )
    }

    // converts HTML to text using Javascript
    Vue.prototype.html2text = (html: string) => {
      const tag = document.createElement('div')
      tag.innerHTML = html
      return tag.innerText
    }

    Vue.prototype.truncate = (str: string, n: number) => {
      return str ? (str.length > n ? `${str.substr(0, n - 1)  }...` : str) : ''
    }

    Vue.prototype.suffle = (array: any) => {
      let currentIndex = array.length,
        temporaryValue: any,
        randomIndex: number

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }

      return array
    }

    Vue.prototype.getTimeRemaining = (endtime: string) => {
      const total = Date.parse(endtime) - new Date().getTime()
      const seconds = Math.floor((total / 1000) % 60)
      const minutes = Math.floor((total / 1000 / 60) % 60)
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
      const days = Math.floor(total / (1000 * 60 * 60 * 24))

      return {
        total,
        days,
        hours,
        minutes,
        seconds
      }
    }

    Vue.prototype.emailIsValid = (email: string) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    Vue.prototype.socketEnabled = () => {
      return process.env.VUE_APP_SOCKET_CONNECT === 'true'
    }

    Vue.prototype.embebedToFrame = (content: string) => {
      if (content) {
        content = content.replace('oembed', 'iframe')
        content = content.replace('url', 'src')
        content = content.replace('watch?v=', 'embed/')
        content = content.replace('oembed', 'iframe')
      }
      return content
    }

    Vue.prototype.parseFormula = function(elementId: string) {
      const descriptionElement = document.getElementById(elementId)
      if (window.wiris === null || window.wiris === undefined) {
        this.$loadScript(
          'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image'
        )
          .then(() => {
            window.wiris = com.wiris.js.JsPluginViewer
            this.$nextTick(function() {
              try {
                window.wiris.parseElement(
                  descriptionElement,
                  true,
                  function() {}
                )
              // eslint-disable-next-line no-empty
              } catch (e) {}
            })
          })
          .catch(() => {})
      } else {
        this.$nextTick(() => {
          try {
            window.wiris.parseElement(descriptionElement, true, function() {})
          } catch (e) {}
        })
      }
    }

    Vue.prototype.time2TimeAgo = function(ts: number) {
      // This function computes the delta between the
      // provided timestamp and the current time, then test
      // the delta for predefined ranges.

      const d = new Date() // Gets the current time
      const nowTs = Math.floor(d.getTime() / 1000) // getTime() returns milliseconds, and we need seconds, hence the Math.floor and division by 1000
      const seconds = nowTs - ts

      // more that two days
      if (seconds > 2 * 24 * 3600) {
        return i18n.t('a-alguns-dias')
      }
      // a day
      if (seconds > 24 * 3600) {
        return i18n.t('ontem')
      }

      if (seconds > 3600) {
        return i18n.t('a-algumas-horas')
      }
      if (seconds > 1800) {
        return i18n.t('a-alguns-minutos')
      }
      if (seconds > 60) {
        return i18n.t('a-instantes-atras')
      }

      if (seconds < 10) {
        return i18n.t('elapsed-now')
      }
    }

    Vue.prototype.readableHumanTime = function(then: string | number | Date, brief = false) {
      const thenDate = (new Date(then))
      const nowInSeconds = Math.floor((new Date()).getTime() / 1000)
      const thenInSeconds = Math.floor(thenDate.getTime() / 1000)
      const seconds = nowInSeconds - thenInSeconds

      if (brief) {

        //Behold the easter egg, if 20 years has passed since then...
        if (seconds > (24 * 365 * 3600 * 20)) {
          const old = String.fromCodePoint(129491)
          return i18n.t('a-uma-geracao-atras-old', [old])
        }

        if (seconds > (24 * 365 * 3600)) {
          const years = seconds / (3600 * 24 * 365)
          return i18n.t('a-years-tofixed-0-ano-years-greater-than', [years.toFixed(0), years >= 2 ? 's' : ''])
        }
        if (seconds > (24 * 30 * 3600)) {
          const months = seconds / (3600 * 24 * 30)
          return i18n.t('a-months-tofixed-0-meses-months-greater', [months.toFixed(0), months >= 2 ? 's' : ''])
        }
        if (seconds > 48 * 3600) {
          const days = seconds / (3600 * 24)
          return i18n.t('a-days-tofixed-0-dia-days-greater-than-2', [days.toFixed(0), days >= 2 ? 's' : ''])
        }
        if (seconds > 24 * 3600) {
          return i18n.t('ontem')
        }
        if (seconds > 2 * 3600) {
          return i18n.t('a-seconds-3600-tofixed-0-h-atras', [(seconds / 3600).toFixed(0)])
        }
      }

      if (seconds > 2 * 3600) {
        return thenDate.toLocaleString('pt-BR')
      }

      if (seconds > 3600) {
        return i18n.t('a-1h-atras')
      }

      if (seconds > 1 / 2 * 3600) {
        return i18n.t('a-meia-hora-atras')
      }

      if (seconds > 600) {
        return i18n.t('a-alguns-minutos-atras')
      }

      if (seconds > 300) {
        return i18n.t('a-poucos-minutos-atras')
      }

      if (seconds > 10) {
        return i18n.t('a-instantes-atras')
      }

      if (seconds < 10) {
        return i18n.t('elapsed-now')
      }
    }

    Vue.prototype.isSmallScreen = (w = 400) => {
      return window.innerWidth < w
    }

    Vue.prototype.shortTitle = (title: any, length = 100) => {
      return Vue.prototype.truncate(Vue.prototype.html2text(title), length)
    }

    Vue.prototype.formatScore = (score: any) => {
      return score ? parseFloat(score).toFixed(2).replace('.', ',') : 0
    }

    Vue.prototype.formatTextAreaToHtml = (text: any) => {
      return text ? text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ''
    }

    Vue.prototype.destroyChilds = (instance: { $children: any[] }) => {
      if (instance && instance.$children && instance.$children.length > 0) {
        instance.$children.forEach((child: { $destroy: () => void }) => {
          child.$destroy()
        })
      }
    }

    Vue.prototype.capitalize = (string: string) => {
      return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
    }

    // Check if tour started and persiste to localStorage if persist param is true.
    Vue.prototype.tourStarted = (tourName: string, persist = false) => {
      let tourInfo: any = localStorage.getItem('tourInfo')
      tourInfo = tourInfo ? JSON.parse(tourInfo) : {}

      if (persist && !(tourName in tourInfo) || tourInfo[tourName] === false) {
        tourInfo[tourName] = true
        localStorage.setItem('tourInfo', JSON.stringify(tourInfo))
        return false
      }

      if (tourInfo) {
        return tourInfo[tourName] === true
      }

      return false
    }

    Vue.prototype.startTour = function (tourName: string) {
      tourName = `tour-${tourName}`
      this.$root.$emit(tourName)
    }

    Vue.prototype.getCurrentBreakpoint = function () {
      return getCurrentBreakpoint()
    }

    Vue.prototype.satisfyCurrentBreakpoint = function (breakpoint: string | number) {
      const currentBreakpoint = this.getCurrentBreakpoint()
      return BREAKPOINT_ORDER[currentBreakpoint] >= BREAKPOINT_ORDER[breakpoint]
    }

    Vue.prototype.topAnimationMove = function (elem: { offsetTop: any; style: { top: string } } | null, posEnd: number, interval = 5, topLimit = 0) {
      let pos = elem ? elem.offsetTop : 0
      const directionDown = !(pos > posEnd)

      if (!directionDown && posEnd < topLimit) {
        posEnd = topLimit
      }

      const id = setInterval(() => {
        if ((directionDown && pos >= posEnd) || (!directionDown && posEnd >= pos) || elem === null) {
          if (directionDown) {
            const diff = pos - posEnd
            if (elem && diff > 0) {
              elem.style.top = `${pos - diff  }px`
            }
          } else {
            const diff = posEnd - pos
            if (elem && diff > 0) {
              elem.style.top = `${pos + diff  }px`
            }
          }
          clearInterval(id)
        } else {

          if (directionDown) {
            pos += 40
          } else {
            pos -= 40
          }

          if (elem !== null) {
            elem.style.top = `${pos  }px`
          }
        }
      }, interval)

    }

    Vue.prototype.offset = (el) => {
      const rect = el.getBoundingClientRect()
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    /**
     * Doesnt really return the height of the element, rather it returns the distance
     * in y to the top of the page. Use this for automatically scrolling to an element's position.
     */
    Vue.prototype.getElementHeight = (elementID: string) => {
      const element = document.getElementById(elementID)
      if (element) {
        const elementTop = element.getBoundingClientRect().top || 0
        const documentTop = document.body.getBoundingClientRect().top || 0
        return elementTop  - documentTop
      }
      return 0
    }

    Vue.prototype.getElementDimensions = (cssSelector: string) => {
      const element = document.querySelector(cssSelector)
      if (element) {
        return {
          witdh: element.clientWidth,
          height: element.clientHeight
        }
      }
    }

    // a/39494245
    Vue.prototype.smoothScroll = function(elementY: number, duration: number) {
      const startingY = window.pageYOffset
      const diff = elementY - startingY
      let start: number

      // Bootstrap our animation - it will get called right before next frame shall be rendered.
      window.requestAnimationFrame(function step(timestamp: number) {
        if (!start) start = timestamp
        // Elapsed milliseconds since start of scrolling.
        const time = timestamp - start
        // Get percent of completion in range [0, 1].
        const percent = Math.min(time / duration, 1)

        window.scrollTo(0, startingY + diff * percent)

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
          window.requestAnimationFrame(step)
        }
      })
    }

    Vue.prototype.isElectron = () => {
      // Renderer process
      if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true
      }

      // Main process
      if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true
      }

      // Detect the user agent when the `nodeIntegration` option is set to true
      if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return true
      }

      return false
    }

    Vue.prototype.validateEnvRestrictionDesktop = (envRestriction: { device: any }) => {

      // If doesn't restriction, is valid.
      if (Vue.prototype.isEmpty(envRestriction)) return true

      const deviceEnv = envRestriction.device
      if (deviceEnv && deviceEnv.includes(PROCTORING_ENV_RESTRICTION.DEVICE_DESKTOP)) {
        return true
      }

      return false
    }

    Vue.prototype.validateEnvRestriction = (envRestriction: { device: any; os: any; browser: any }) => {

      // If doesn't restriction, is valid.
      if (Vue.prototype.isEmpty(envRestriction)) return true

      const browser = Bowser.getParser(window.navigator.userAgent)
      console.log(browser)

      const deviceEnv = envRestriction.device
      const osEnv = envRestriction.os
      const browserEnv = envRestriction.browser

      const osValidated = osEnv ? osEnv.includes(browser.getOSName(true)) : false
      const browserValidated = browserEnv ? browserEnv.includes(browser.getBrowserName(true)) : false

      if (deviceEnv && deviceEnv.includes(PROCTORING_ENV_RESTRICTION.DEVICE_DESKTOP)
            && deviceEnv && deviceEnv.includes(PROCTORING_ENV_RESTRICTION.DEVICE_WEB)) {
        return osValidated && browserValidated
      } else if (deviceEnv && deviceEnv.includes(PROCTORING_ENV_RESTRICTION.DEVICE_DESKTOP)) {
        return osValidated
      } else if (deviceEnv && deviceEnv.includes(PROCTORING_ENV_RESTRICTION.DEVICE_WEB)) {
        return browserEnv.includes(browser.getBrowserName(true))
      }

      return false
    }

    Vue.prototype.envRestrictionMessage = (envRestriction: { device: any; os: any; browser: any }) => {

      // If doesn't restriction, is valid.
      if (Vue.prototype.isEmpty(envRestriction)) return null

      console.log('envRestriction', envRestriction)
      let message = '<ul>Não é possível realizar esta avaliação, por favor verifique as configurações ou entre em contato com o responável.</ul><br/>'

      if (envRestriction) {

        const browser = Bowser.getParser(window.navigator.userAgent)
        const deviceEnv = envRestriction.device
        const osEnv = envRestriction.os
        const browserEnv = envRestriction.browser

        if (browserEnv.length > 0) {
          message += 'Navegadores liberados:<br/>'
          browserEnv.forEach(element => {
            const urlBrowserDownload = Vue.prototype.browserDownloadLink(element)

            message += `&nbsp;&nbsp;&nbsp;- ${element} `
            message += ` - <a target="blank" href="${urlBrowserDownload}">Clique aqui para baixar </a><br/>`
          })
        }

        if (deviceEnv.length > 0) {
          message += 'Dispositivos liberados:<br/>'
          deviceEnv.forEach(element => {
            message += `&nbsp;&nbsp;&nbsp;- ${element}<br/>`
          })
        }

        if (osEnv.length > 0) {
          message += 'Sistemas operacionais liberados:<br/>'
          osEnv.forEach(element => {
            message += `&nbsp;&nbsp;&nbsp;- ${element}<br/>`
          })
        }

      }

      return message
    }

    /**
     * Check if event parent of ckeditor is a table element.
     * This code is used to hack table cell blur event handled when ckeditor table is selected.
     */
    Vue.prototype.isTableParent = (e: any) => {
      // Check if selected element is a parent table
      let parent = e.source.selection.anchor.parent
      if (e && parent) {
        while (parent !== null) {
          if (parent.name === 'table') {
            return true
          }
          parent = parent.parent
        }
      }
      return false
    }

    Vue.prototype.systemLogo = () => {
      return `${process.env.VUE_APP_API_BASE_URL}/img/logo-text.png`
    }

    Vue.prototype.updateLogoDomain = (domainConfig: any, store: any) => {
      if (domainConfig && domainConfig.logos) {
        if (domainConfig.logos.logo) {
          store.dispatch('common/updateLogo', domainConfig.logos.logo)
        }
        if (domainConfig.logos.smallLogo) {
          store.dispatch('common/updateSmallLogo', domainConfig.logos.smallLogo)
        }
      }
    }

  }

}
