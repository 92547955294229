import Vue from 'vue'
import router from '@/router'
import store from '@/store/store'

export default {
  setPeopleId({ dispatch }: any, payload: any) {
    dispatch('changePeopleId', payload)
  },
  changePeopleId({ commit, state, dispatch }: any, payload: any) {
    console.log('changePeopleId', payload)
  },
  setCurrentTour({ commit, state, dispatch }: any, payload: any) {
    commit('UPDATE_CURRENT_TOUR', payload)
  },
  setRoleModalVisibility({commit, state, dispatch}:any, payload: Boolean) {
    commit('ROLE_MODAL_VISIBILITY', payload)
  },
  disableContextMenu({commit, state, dispatch}:any) {
    commit('DISABLED_CONTEXT_MENU', false)
  },
  enableContextMenu({commit, state, dispatch}:any) {
    commit('DISABLED_CONTEXT_MENU', true)
  },
  updateSmallLogo({commit, state, dispatch}:any, payload: any) {
    commit('UPDATE_SMALL_LOGO', payload)
  },
  updateLogo({commit, state, dispatch}:any, payload: any) {
    commit('UPDATE_LOGO', payload)
  },
  updateDomainConfig({commit, state, dispatch}:any, payload: any) {
    commit('UPDATE_DOMAIN_CONFIG', payload)
  },
}
