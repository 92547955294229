export enum PROCTORING_SOCKET_TYPE {
  CallSupport = 'callSupport',
  CloseSupport = 'closeSupport',
  OpenSupportConfirmed = 'openSupportConfirmed',
  OpenSupportCanceled = 'openSupportCanceled',
  ConnectionSpeed = 'connectionSpeed',
  AskConnectionSpeed = 'askConnectionSpeed',
}

export enum PROCTORING_STREAM_TYPE {
  LiveRecord = 'LR',
  Live = 'L',
  Record = 'R',
  LiveChat = 'LC',
  LiveChatRecord = 'LCR',
}

export enum PROCTORING_ENV_RESTRICTION {
  DEVICE_DESKTOP = 'desktop',
  DEVICE_WEB = 'web',
  OS_LINUX = 'linux',
  OS_MAC = 'macos',
  OS_WINDOWS = 'win',
  BROWSER_MOZILLA = 'firefox',
  BROWSER_CHROME = 'chrome',
  BROWSER_OPERA = 'opera',
}

export enum QUESTION_STATUS {
  NOT_ANSWERED = 'not_answered',
  ANSWERED = 'answered',
  ENQUEUED = 'enqueued',
  ERROR_TO_SAVE = 'error_to_save',
}

export enum EVENT_SEVERITY {
  L = 'L',
  M = 'M',
  H = 'H'
}

export enum PROCTORING_TEST_STATUS {
  SUCCESS = 'S',
  WARNING = 'W',
  EERROR = 'E'
}
