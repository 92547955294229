import Http from './Http'

/**
 * @type Api
 */
export default class Api extends Http {
  /**
   * @param {String} path
   * @param {Object} http
   * @return {this}
   */
  static build(path = '', http = null, vs = null, baseURL = null) {
    return new this(
      Api.normalize(`${process.env.VUE_APP_API_BASE_URL}`, path),
      http,
      vs,
      baseURL
    )
  }
}
