const WINDOW_WIDTH_SM: number = 640
const WINDOW_WIDTH_MD: number = 768
const WINDOW_WIDTH_XL: number = 1280
const WINDOW_WIDTH_2XL: number = 1536

export const BREAKPOINT_ORDER = {
  'sm': 1,
  'md': 2,
  'lg': 3,
  'xl': 4,
  '2xl': 5
}

export const MOMENT_THRESHOLDS = {
  'ss': 1,
  's': 60,
  'm': 60,
  'h': 24,
  'd': 30,
  'w': null,
  'M': 12
}

export const newUserInfo = () => {
  return {
    id: null,
    name: null,
    phone: null,
    email: null,
    profile_picture: '',
    people: [],
    firstAccess: false,
    email_verified_at: '',
    roles: [],
    token: ''
  }
}

export const proctoring = {
  events: {
    colors: {
      'L': 'success',
      'M':'warning',
      'H':'danger'
    },
    icons: {
      'comment': 'FeatherIcon',
      're_entry': 'ChevronsRightIcon',
      'start': 'PlayCircleIcon',
      'finish': 'StopCircleIcon',
    }
  }
}

export default {
  WINDOW_WIDTH_SM,
  WINDOW_WIDTH_MD,
  WINDOW_WIDTH_XL,
  WINDOW_WIDTH_2XL,
  BREAKPOINT_ORDER
}