import Rest from '../Rest'
import store from '@/store/store'

export default class LoginService extends Rest {

  /**
   * @type {String}
   */
  static resource = '/api';

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  updateProfile(fields: any) {
    return this.post('/v1/profile', fields).then(data => {
      store.commit('UPDATE_USER_INFO', fields)
      return data
    })
  }  
}