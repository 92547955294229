import Vue from 'vue'
import router from '@/router'
import store from '@/store/store'

export default {
  selectQuestion({commit, state}:any, question: any) {
    const exists = state.selectedQuestions.find( (search) => {
      return search.id == question.id
    })
    if (exists === undefined) {
      commit('ADD_SELECTED_QUESTION', question)
    } else {
      Vue.prototype.devLog('BaseQuestionStore', 'Question ', question.id, ' already exists in the selection...')
    }
  },
  deselectQuestion({commit, state}, questionId) {
    const exists = state.selectedQuestions.find((search) => search.id === questionId)
    if (exists !== undefined) {
      commit('REMOVE_SELECTED_QUESTION', questionId)
    }
  },
  wipeSelections({commit}) {
    commit('WIPE_SELECTIONS')
  }
}
