/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import App from './App.vue'

import '@/util/String'

// Vuesax Component Framework
import Vuesax from 'educatena-vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'educatena-vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

// axios
import standard from '@/services/standard'
Vue.prototype.$http = standard.axios

// Theme Configurations
import '../themeConfig'

// Auth0 Plugin
import AuthPlugin from './plugins/auth'
Vue.use(AuthPlugin)

import VueUtil from './util/VueUtils'
Vue.use(VueUtil)

import ProctoringUtils from './util/ProctoringUtils'
Vue.use(ProctoringUtils)

import { ClassHoverDirective, ESCDirective } from './util/Directives'
Vue.directive('class-hover', ClassHoverDirective)
Vue.directive('esc', ESCDirective)

import normalizeUrl from 'normalize-url'

// Globally Registered Components
import './globalComponents'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// Acl
import acl from './acl/directive'
Vue.use(acl)

// i18n
import i18n from './i18n/i18n'

// Vuesax Admin Filters
import './filters/filters'

// Font awesome icon
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { faUser } from '@fortawesome/free-regular-svg-icons'
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

library.add(faFontAwesome)
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require('./assets/css/iconfont.css')

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

Vue.use(require('vue-moment'))

// CKEditor
import CKEditor from '@ckeditor/ckeditor5-vue'
Vue.use(CKEditor)

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour as any)
require('vue-tour/dist/vue-tour.css')

import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-184183689-1',
  router,
  checkDuplicatedScript: true,
  autoTracking: {
    exception: true
  },
  debug: {
    sendHitTask: process.env.VUE_APP_ANALYTICS_ENABLED
  }
})

// Socket
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

if (eval(`${process.env.VUE_APP_SOCKET_CONNECT}`)) {
  const options = {}
  Vue.use(
    new VueSocketIO({
      debug: true,
      connection: SocketIO(`${process.env.VUE_APP_API_SOCKET_URL}:6001`, options),
      vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
      }
    })
  )
}

// Components
import Grid from './components/Grid.vue'
Vue.component('Grid', Grid)

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { name: 'custom', lodash })

import uuid from '@estudioliver/vue-uuid-v4'
Vue.use(uuid)

import underscore from 'vue-underscore'
Vue.use(underscore)

Vue.config.productionTip = false

Vue.config.silent = true

import UserClientExceptionService from '@/services/api/UserClientExceptionService'
Vue.prototype.$exceptionService = UserClientExceptionService.build(Vue.prototype.$vs)
Vue.prototype.$normalizeUrl = normalizeUrl
// Vue.prototype.$exceptionService.bootstrapQueue()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
