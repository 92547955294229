/*=========================================================================================
  File Name: moduleNaturalEntityGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  available: state => {
    return state.institutions
  },
  organizationLogo: state => {
    return state.organizationLogo
  },
  active: state => {
    return state.active_institution
  },
  showModal: state => {
    return state.showModal
  }
}
