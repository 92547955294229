import Api from './Api'

/**
 * @typedef {Rest}
 */
export default class Rest extends Api {
  /**
   * @type {String}
   */
  static resource = ''

  /**
   * @type {String}
   */
  id = 'id'

  /**
   * @param {String} resource
   * @param {Object} http
   */
  constructor(resource: string, http = null, vs: any = null, baseURL: any = null) {
    super(
      Rest.normalize(baseURL ? baseURL : process.env.VUE_APP_API_BASE_URL, resource),
      http,
      vs,
      baseURL
    )
  }

  /**
   * @return {this}
   */
  static build(vs: any = null, baseURL: any = null) {
    return new this(this.resource, null, vs, baseURL)
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  create(record: any) {
    return this.post('', record)
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  createOrUpdate(record: any) {
    if (
      !record.hasOwnProperty('id') ||
      typeof record['id'] === undefined ||
      record['id'] === null ||
      record['id'] === undefined
    ) {
      delete record['id']
      return this.create(record)
    }
    return this.update(record)
  }

  /**
   *
   * @param path
   * @param data
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  grid(path: string = '', data: any) {
    if (path !== '') {
      return this.get(`${path}`, data)
    }
    return this.get('grid', data)
  }

  /**
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  all() {
    return this.get('')
  }

  /**
   * @param {String|Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  read(record: any, extra?: any) {
    return this.get(`/${this.getId(record)}`, extra)
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  update(record: any) {
    return this.put(`/${this.getId(record)}`, record)
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  destroy(record: any) {
    return this.delete(`/${this.getId(record)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  search(parameters = {}) {
    const queryString = ''
    // apply your logic here
    return this.get(`?${queryString}`).then((response: { rows: any }) => ({
      rows: response.rows // just an example
    }))
  }

  /**
   * @param {String|Object} record
   * @returns {String}
   */
  getId(record: any) {
    if (typeof record === 'object') {
      return record[this.id]
    }
    return String(record)
  }
}
