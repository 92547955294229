import i18n from '@/i18n/i18n'

export default {
  pages: {
    key: 'title',
    data: [
      // DASHBOARD
      {
        title: i18n.t('Dashboard'),
        url: '/',
        icon: 'HomeIcon',
        is_bookmarked: true,
        rule: 'dashboard'
      },

      // PROFESSOR
      {
        title: i18n.t('questionarios'),
        url: '/questionnaires',
        icon: 'FileTextIcon',
        is_bookmarked: false,
        rule: 'questionnaires.menu'
      },
      {
        title: i18n.t('criar-questionario'),
        url: '/questionnaires/create',
        icon: 'FileTextIcon',
        is_bookmarked: false,
        rule: 'questionnaires.create'
      },
      {
        title: i18n.t('classes'),
        url: '/classes',
        icon: 'UsersIcon',
        is_bookmarked: false,
        rule: 'classes.menu',
      },
      {
        title: i18n.t('criar-turma'),
        url: '/classes/create',
        icon: 'UsersIcon',
        is_bookmarked: false,
        rule: 'classes.create',
      },
      {
        title: i18n.t('disciplines'),
        url: '/disciplines',
        icon: 'PenToolIcon',
        is_bookmarked: false,
        rule: 'disciplines.menu'
      },
      {
        title: i18n.t('criar-disciplina'),
        url: '/disciplines/create',
        icon: 'PenToolIcon',
        is_bookmarked: false,
        rule: 'disciplines.create'
      },
      {
        title: i18n.t('banco-de-questao'),
        url: '/base_question',
        icon: 'ArchiveIcon',
        is_bookmarked: true,
        rule: 'base_question.menu'
      },

      // ALUNO
      {
        title: i18n.t('meus-questionarios'),
        url: '/questionnaires/answers',
        icon: 'FileTextIcon',
        rule: 'questionnaires.menu.answers'
      },
      { 
        title: i18n.t('minhas-turmas'),
        url: '/classes_students',
        icon: 'UsersIcon',
        is_bookmarked: false,
        rule: 'classes.students.menu'
      },
      {
        title: i18n.t('confirmar-convite-de-turma'),
        url: '/class_invite',
        icon: 'MailIcon',
        is_bookmarked: false,
        rule: 'classes_students.invite',
      },


      // ADMIN
      {
        title: i18n.t('Users'),
        url: '/users',
        icon: 'UserIcon',
        rule: 'users.menu',
        is_bookmarked: false
      },
      {
        title: i18n.t('Roles'),
        url: '/roles',
        icon: 'PackageIcon',
        rule: 'roles.menu',
        is_bookmarked: false
      },
      {
        title: i18n.t('cadastrar-usuario'),
        url: '/users/create',
        icon: 'UserIcon',
        rule: 'users.create',
        is_bookmarked: false
      },
      {
        title: i18n.t('cadastrar-grupo'),
        url: '/roles/create',
        icon: 'PackageIcon',
        rule: 'roles.create',
        is_bookmarked: false
      },
      {
        title: i18n.t('organization'),
        url: '/organizations',
        icon: 'GlobeIcon',
        rule: 'organizations.grid',
        is_bookmarked: false
      },
      {
        title: i18n.t('organization_create'),
        url: '/organizations/create',
        icon: 'GlobeIcon',
        rule: 'organizations.create',
        is_bookmarked: false
      },
      {
        title: i18n.t('suggestable_models.BaseQuestionInstitution'),
        url: '/institutions',
        icon: 'HexagonIcon',
        rule: 'institutions.menu',
        is_bookmarked: false
      },
      {
        title: i18n.t('institution_create'),
        url: '/institutions/create',
        icon: 'HexagonIcon',
        rule: 'institutions.create',
        is_bookmarked: false
      },
      {
        title: i18n.t('students'),
        url: '/students',
        icon: 'UserCheckIcon',
        rule: 'students.menu',
        is_bookmarked: false
      },
      { 
        url: '/proctoring_monitor',
        title: i18n.t('proctoring-salas-monitoramento'),
        icon: 'TargetIcon',
        rule: 'proctoring.monitor'
      },
      {
        url: '/proctoring_config',
        title: i18n.t('proctoring.menu.setup'),
        icon: 'VideoIcon',
        rule: 'proctoring_config.show'
      },
      {
        url: '/proctoring_config/create',
        title: i18n.t('criar-configuracao-proctoring'),
        icon: 'VideosIcon',
        rule: 'proctoring_config.show'
      },
      {
        url: '/proctoring_audit',
        title: i18n.t('auditoria-de-gravacoes'),
        icon: 'EyeIcon',
        rule: 'proctoring.audit'
      },
      {
        url: '/email_templates',
        icon: 'MailIcon',
        title: i18n.t('modelos-de-email'),
        rule: 'email_templates.menu'
      },
      {
        url: '/reports/questionnaire/time',
        icon: 'ClockIcon',
        title: i18n.t('avaliacao-duracao'),
        rule: 'reports.questionnaire.time',
      }
    ]
  }
}
/* eslint-enable */
